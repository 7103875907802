import './IconsC.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';

export interface IconsCContentType {
  background?: string;
  icons?: b2x.contentTypes.ContentSectionList<{
    title?: string;
    type?: b2x.contentTypes.ContentSectionSwitch<{
      image?: b2x.contentTypes.ImageContentSectionV2;
      svg?: string;
    }>;
  }>;
  subTitle?: string;
  title?: string;
}

interface IconsCProps extends IconsCContentType {
  mainComponent?: boolean;
}

export const IconsC = ({ background, icons, mainComponent, subTitle, title }: IconsCProps) => {
  return (
    <b2x.Div className="icons-c">
      <Container>
        <b2x.Div background={background} padding={{ lg: 4, xs: 3 }}>
          <b2x.Row>
            <b2x.Col
              size={{
                lg: 6,
                xs: 12,
              }}
            >
              <b2x.Div className="icons-column" display="flex" gap={4} justifyContent="center">
                {icons?.map((icon) => {
                  const type = b2x.getItemFromSwitch(icon.type);

                  return (
                    type?.svg !== 'senza-glutine' && (
                      <b2x.Div
                        alignItems="center"
                        className="icon"
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        key={icon.contentSectionId}
                        textAlign="center"
                      >
                        {type?.svg && isIconName(type.svg) ? (
                          <b2x.Div marginBottom={3}>
                            <Icon name={type.svg} size={50} />
                          </b2x.Div>
                        ) : (
                          type?.image && (
                            <b2x.Div className="asset" marginBottom={3}>
                              <b2x.ImageFromContentV2 width={50} {...type.image} fluid />
                            </b2x.Div>
                          )
                        )}
                        {icon.title && <b2x.P marginBottom={0}>{icon.title}</b2x.P>}
                      </b2x.Div>
                    )
                  );
                })}
              </b2x.Div>
            </b2x.Col>
            <b2x.Col>
              <b2x.Div className="copy-column" display="flex" flexDirection="column" gap={3} justifyContent="center">
                <b2x.ConditionalWrapper
                  condition={title !== undefined}
                  wrapper={
                    mainComponent ? <b2x.H1 className="title" margin={0} /> : <b2x.P className="title" margin={0} />
                  }
                >
                  {title}
                </b2x.ConditionalWrapper>
                <b2x.P className="subTitle" margin={0}>
                  {subTitle}
                </b2x.P>
              </b2x.Div>
            </b2x.Col>
          </b2x.Row>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
