import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'language',
  'account',
  'account-logged-in',
  'alarm',
  'arrow-down-bold',
  'arrow-down-light',
  'arrow-left-bold',
  'arrow-left-light',
  'arrow-right-bold',
  'arrow-right-light',
  'arrow-up-bold',
  'arrow-up-light',
  'calendar',
  'card',
  'close',
  'coupon',
  'delete',
  'delivery',
  'download',
  'error',
  'facebook-rounded',
  'filter',
  'filter-map',
  'geolocator-map',
  'gift-card',
  'gift-sample',
  'headphone',
  'hidden',
  'info',
  'instagram',
  'instagram-rounded',
  'isenza',
  'language',
  'logout',
  'menu',
  'minus',
  'navigator',
  'one-column-display',
  'order',
  'phone',
  'pin',
  'pin-full',
  'play-rounded',
  'plus',
  'privacy',
  'promo',
  'promo-box',
  'search',
  'senza-conservanti',
  'senza-glutine',
  'senza-nickel',
  'senza-profumo',
  'settings',
  'sharing',
  'shipping',
  'shopping-cart',
  'shopping-cart-full',
  'sustainability',
  'tik-tok-rounded',
  'try-rounded',
  'two-column-display',
  'visible',
  'voice-recorder',
  'voice-recorder-on',
  'wishlist',
  'wishlist-full',
  'youtube-rounded',
  'about',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 10 | 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 34 | 36 | 40 | 44 | 48 | 50 | 72;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
