import './InstagramA.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Icon } from '../Icon';

export interface InstagramAContentType {
  head?: {
    title?: string;
  };
}

interface InstagramAProps extends InstagramAContentType {
  mainComponent?: boolean;
}

export const InstagramA = ({ head, mainComponent }: InstagramAProps) => {
  return (
    <b2x.Div className={'instagram-a'} paddingY={{ lg: 5, xs: 3 }}>
      <b2x.Div className="component-head" marginBottom={{ lg: 5, xs: 4 }}>
        <Container>
          <b2x.ConditionalWrapper
            condition={head?.title !== undefined}
            wrapper={mainComponent ? <b2x.H1 className="title" margin={0} /> : <b2x.P className="title" margin={0} />}
          >
            <Icon name="instagram" /> {head?.title}
          </b2x.ConditionalWrapper>
        </Container>
      </b2x.Div>
      <b2x.Div className="component-body">
        <iframe
          className="taggbox"
          src="https://widget.taggbox.com/2140301"
          style={{ width: '100%' }}
          title="taggbox"
        />
      </b2x.Div>
    </b2x.Div>
  );
};
