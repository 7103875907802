import './ProductReviews.scss';

import { b2x } from '@b2x/react/src';

import { useAppStaticContext } from './AppContext';
import { Container } from './Container';

export interface ProductReviewsProps {
  innerRef?: React.RefObject<HTMLDivElement>;
  product: b2x.ProductApiDto;
}

export const ProductReviews = ({ innerRef, product }: ProductReviewsProps) => {
  const { showAccountOffcanvas } = useAppStaticContext();

  return (
    <div ref={innerRef}>
      <section className="bg-nude-100 py-5">
        <Container>
          <b2x.ProductReviews onSignInButtonClick={showAccountOffcanvas} product={product} />
        </Container>
      </section>
    </div>
  );
};
