import './TabsA.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Container } from '../Container';

export interface TabsAContentType {
  body?: {
    tabs?: b2x.contentTypes.ContentSectionList<{
      content?: string;
      title?: string;
    }>;
  };
  foot?: {
    cta?: b2x.contentTypes.CtaContentSection;
  };
  head?: {
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    title?: string;
  };
}

interface TabsAProps extends TabsAContentType {
  mainComponent?: boolean;
}

export const TabsA = ({ body, foot, head, mainComponent }: TabsAProps) => {
  return (
    <b2x.Div className="tabs-a" paddingY={{ lg: 5, xs: 3 }}>
      {(head?.title || head?.content || head?.cta?.label) && (
        <b2x.Div
          className="component-head"
          marginBottom={{
            lg: 5,
            xs: 3,
          }}
          textAlign={'center'}
        >
          <Container>
            <b2x.ConditionalWrapper
              condition={head.title !== undefined}
              wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
            >
              {b2x.formatHtml(head.title)}
            </b2x.ConditionalWrapper>
            {head.content && (
              <b2x.P className="content" marginBottom={{ lg: 3 }}>
                {b2x.formatHtml(head.content)}
              </b2x.P>
            )}
            {head.cta?.label && (
              <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                <b2x.CtaFromContent {...head.cta} variant="link" />
              </b2x.Div>
            )}
          </Container>
        </b2x.Div>
      )}
      <b2x.Div
        className="component-body"
        marginBottom={{
          lg: 4,
          xs: 3,
        }}
      >
        <Container>
          <b2x.Row justifyContent={'center'}>
            <b2x.Col size={{ lg: 8, xs: 12 }}>
              <TabsContent tabItems={body?.tabs} />
            </b2x.Col>
          </b2x.Row>
        </Container>
      </b2x.Div>
      {foot?.cta?.label && (
        <b2x.Div className="component-foot" textAlign={'center'}>
          <Container>
            <b2x.CtaFromContent {...foot.cta} variant="link" />
          </Container>
        </b2x.Div>
      )}
    </b2x.Div>
  );
};

interface TabsContentProps {
  tabItems?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    title?: string;
  }>;
}

const TabsContent = ({ tabItems }: TabsContentProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<b2x.SwiperClass>();

  return (
    <b2x.Div className="tabs-content">
      <b2x.Div className="swiper-tab-nav-container" marginBottom={{ lg: 5, xs: 3 }}>
        <b2x.SwiperContext>
          {(swiper) => (
            <b2x.Swiper
              loop={false}
              onSwiper={setThumbsSwiper}
              slidesPerView={'auto'}
              spaceBetween={32}
              watchSlidesProgress
            >
              {tabItems?.map((tabItem, index) => (
                <b2x.SwiperSlide key={`tab-nav-${tabItem.contentSectionId}`}>
                  <b2x.Div className={'tab-nav'}>{b2x.formatHtml(tabItem.title)}</b2x.Div>
                </b2x.SwiperSlide>
              ))}
            </b2x.Swiper>
          )}
        </b2x.SwiperContext>
      </b2x.Div>
      <b2x.Div className="swiper-tab-pane-container" marginBottom={{ lg: 5, xs: 3 }}>
        <b2x.SwiperContext>
          {(swiper) => (
            <b2x.Swiper
              allowTouchMove={false}
              draggable={false}
              effect="fade"
              slides={tabItems?.map((tabItem, index) => (
                <b2x.Div key={`tap-pane-${tabItem.contentSectionId}`}>
                  {tabItem.content && <b2x.Div className="tab-pane">{b2x.formatHtml(tabItem.content)}</b2x.Div>}
                </b2x.Div>
              ))}
              slidesPerView={1}
              spaceBetween={0}
              thumbs={{ swiper: thumbsSwiper }}
            />
          )}
        </b2x.SwiperContext>
      </b2x.Div>
    </b2x.Div>
  );
};
