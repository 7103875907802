import './MobileNavigationOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { Container } from './Container';
import { MenuMobileOffcanvasContentType, MenuMobileOffcanvasItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import srcMobile from './images/logo-mobile.svg';
import { SocialIcons } from './SocialIcons';

interface MobileNavigationOffcanvasProps extends b2x.OffcanvasProps {}

const MobileNavigationOffcanvas = (props: MobileNavigationOffcanvasProps) => {
  const menu = b2x.useMenu('MENU_HEADER_MOBILE', { populate: { content: true } });

  return (
    <b2x.Offcanvas className="mobile-navigation-offcanvas" {...props}>
      <div className="d-flex flex-column h-100 bg-white">
        <Header />
        <div className="flex-grow-1 position-relative">
          <b2x.Drawers>
            <div className="d-flex flex-column h-100">
              <div className="flex-grow-1">{menu && <DrawerMenuItems item={menu} />}</div>
              <Footer />
            </div>
          </b2x.Drawers>
        </div>
      </div>
    </b2x.Offcanvas>
  );
};

const Header = () => {
  const { close } = b2x.useModalContext();

  return (
    <div className="mobile-navigation-offcanvas-header border-bottom position-relative py-2">
      <Container>
        <div className="text-center">
          <b2x.router.Link onClick={close} to="/">
            <b2x.Image src={srcMobile} />
          </b2x.router.Link>
        </div>
      </Container>
      <div className="position-absolute top-0 end-0 p-2">
        <Button className="p-2" iconEnd={{ name: 'close', size: 20 }} onClick={close} variant="blank" />
      </div>
    </div>
  );
};

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const content = b2x.useContent<MenuMobileOffcanvasContentType>('MENU_MOBILE_OFFCANVAS_CONTENT');
  // const { close } = b2x.useModalContext();

  return (
    <div className="mobile-navigation-offcanvas-footer">
      <DrawerAccountItem />
      <Container className="g-2">
        <b2x.Row className="mb-2" gap={2}>
          {content?.body.ctaList?.map(({ contentSectionId, cta, size }) => (
            <b2x.Col key={contentSectionId} size={size === 'small' ? 6 : 12}>
              <div className="d-grid">
                <b2x.CtaFromContent
                  {...cta}
                  ctaProps={{
                    button: {
                      className: 'text-initial px-3',
                      iconStart: cta && isIconName(cta.icon) ? { name: cta.icon, size: 20 } : undefined,
                      justifyContent: 'start',
                    },
                    link: { closeModal: true },
                  }}
                  variant="nude"
                />
              </div>
            </b2x.Col>
          ))}
        </b2x.Row>
      </Container>

      <div className="border-top">
        <DrawerLocaleItem />
      </div>

      <Container>
        <b2x.Div display={'flex'} justifyContent={'center'} paddingY={3}>
          <SocialIcons size={36} />
        </b2x.Div>
      </Container>
    </div>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = t('misc.back') }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <div>
      <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
        <b2x.DrawerItem
          center={{ center: label }}
          className="extra-small position-relative"
          height={35}
          justifyContent="center"
          start={{
            center: (
              <div className="position-absolute start-0 top-0 h-100 d-flex align-items-center ps-3">
                <Icon name="arrow-left-bold" size={10} />
              </div>
            ),
          }}
        />
      </b2x.DrawerButtonItem>
    </div>
  );
};

interface DrawerMenuItemsProps {
  depth?: number;
  item: b2x.MenuApiDto;
  parentItem?: b2x.MenuApiDto;
}

const DrawerMenuItems = ({ depth = 1, item, parentItem }: DrawerMenuItemsProps) => {
  const _parentItem = depth === 2 ? item : parentItem;

  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <>
      <div className="drawer-menu-items small">
        {item.children.map((child) => {
          const hasChildren = child.children.length > 0;

          return hasChildren ? (
            <b2x.DrawerTriggerItem
              drawerButtonClassName={child.tags?.includes('featured') ? 'featured' : undefined}
              key={child.id}
              newDrawer={
                <Drawer backItemProps={{ label: child.label }}>
                  <DrawerMenuItems depth={depth + 1} item={child} parentItem={_parentItem} />
                </Drawer>
              }
            >
              <b2x.DrawerItem
                className={classnames('px-3', child.cssClass)}
                end={{ center: <Icon name="arrow-right-bold" size={10} /> }}
                height={46}
                justifyContent="between"
                start={{
                  center: (
                    <span>
                      {isIconName(child.iconName) && <Icon name={child.iconName} size={20} />}
                      {child.label}
                    </span>
                  ),
                }}
              />
            </b2x.DrawerTriggerItem>
          ) : (
            <b2x.DrawerLinkItem
              className={classnames({ 'featured bg-nude border-white': child.tags?.includes('featured') })}
              key={child.id}
              to={child.link}
            >
              <b2x.DrawerItem
                className={'px-3'}
                height={46}
                justifyContent="start"
                start={{
                  center: (
                    <Button
                      className={classnames(
                        { 'fw-bold text-decoration-underline text-initial': child.tags?.includes('featured') },
                        { 'text-initial fw-normal': depth !== 1 && !child.tags?.includes('featured') },
                        child.cssClass
                      )}
                      iconStart={isIconName(child.iconName) ? { name: child.iconName, size: 20 } : undefined}
                      label={child.label}
                      variant="blank"
                    />
                  ),
                }}
              />
            </b2x.DrawerLinkItem>
          );
        })}
        {_parentItem?.label && (
          <b2x.DrawerLinkItem to={_parentItem.link}>
            <b2x.DrawerItem
              className="px-3 fw-bold text-decoration-underline"
              height={46}
              justifyContent="start"
              start={{ center: _parentItem.note ?? t('misc.viewAll') }}
            />
          </b2x.DrawerLinkItem>
        )}
      </div>
      {_parentItem?.content &&
        b2x.typedContent<MenuMobileOffcanvasItemContentType>(_parentItem.content, (content) => (
          <div className="drawer-menu-items-content py-3">
            {content.body.ctaList && content.body.ctaList.length > 0 && (
              <Container>
                <b2x.Row gap={3}>
                  {content.body.ctaList.map(({ contentSectionId, cta, image }) => (
                    <b2x.Col key={contentSectionId} size={12}>
                      {image?.src?.xs ? (
                        <b2x.router.Link
                          className="border border-dark d-flex text-decoration-none text-uppercase small fw-bold"
                          closeModal
                          to={cta?.to?.href ? cta.to.href : cta?.to?.code && getPagePath(cta.to.code)}
                        >
                          <div className="w-50">
                            <b2x.ImageFromContentV1 className="w-100 h-100" style={{ objectFit: 'cover' }} {...image} />
                          </div>
                          <div
                            className="w-50 p-3 d-flex justify-content-start
                           align-items-center"
                          >
                            {cta?.label}
                          </div>
                        </b2x.router.Link>
                      ) : (
                        <div className="d-grid">
                          <b2x.CtaFromContent
                            {...cta}
                            ctaProps={{
                              button: {
                                className: 'px-3',
                                iconStart: cta && isIconName(cta.icon) ? { name: cta.icon, size: 20 } : undefined,
                                justifyContent: 'start',
                              },
                              link: { closeModal: true },
                            }}
                            variant="outline-primary"
                          />
                        </div>
                      )}
                    </b2x.Col>
                  ))}
                </b2x.Row>
              </Container>
            )}
          </div>
        ))}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

const DrawerAccountItem = () => {
  const { close } = b2x.useModalContext();
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const accountOffcanvasContent =
    b2x.useContent<b2x.contentTypes.AccountOffcanvasContentType>('ACCOUNT_OFFCANVAS_CONTENT');
  return session?.customer ? (
    <Container className="g-2 mb-2">
      <b2x.Row gap={2}>
        <b2x.Col size={12}>
          <div className="d-grid">
            <b2x.router.Link
              className="btn btn-nude-100 text-initial d-inline-flex gap-2 align-items-center justify-content-start px-3"
              onClick={close}
              to={getPagePath('SITE_ACCOUNT')}
            >
              <Icon name="account" size={20} />
              {t('misc.welcomeMessage', { name: session.customer.name })}
            </b2x.router.Link>
          </div>
        </b2x.Col>
        <b2x.Col size={6}>
          <div className="d-grid">
            <b2x.router.Link
              className="btn btn-nude-100 text-initial d-inline-flex gap-2 align-items-center justify-content-start px-3"
              onClick={close}
              to={getPagePath('SITE_CCN_WISHLIST')}
            >
              <Icon name="wishlist" size={20} />
              {t('misc.wishlist')}
            </b2x.router.Link>
          </div>
        </b2x.Col>
        <b2x.Col size={6}>
          <div className="d-grid">
            <b2x.router.Link
              className="btn btn-nude-100 text-initial d-inline-flex gap-2 align-items-center justify-content-start px-3"
              onClick={close}
              to={getPagePath('SITE_CCN_ORDERS')}
            >
              <Icon name="shopping-cart" size={20} />
              {t('misc.myOrders')}
            </b2x.router.Link>
          </div>
        </b2x.Col>
      </b2x.Row>
    </Container>
  ) : (
    <b2x.DrawerTriggerItem
      drawerButtonClassName="p-2 border-0"
      newDrawer={
        <Drawer>
          <div className="d-flex flex-column h-100 justify-content-between">
            <div className="p-4">
              <h4 className="text-uppercase fw-semi-bold mb-3">{t('account.login')}</h4>
              <h6 className="fw-light mb-3">{t('account.insertCredentials')}</h6>
              <b2x.LoginForm onSuccess={close} />
            </div>
            <div>
              <div className="bg-nude p-4">
                <h4 className="text-uppercase fw-semi-bold mb-3">
                  {b2x.formatHtml(accountOffcanvasContent?.body.registration?.title)}
                </h4>
                <h6 className="fw-light mb-4">{b2x.formatHtml(accountOffcanvasContent?.body.registration?.body)}</h6>
                <div className="d-grid">
                  <b2x.router.Link className="btn btn-primary text-uppercase" onClick={close} to="/register">
                    {t('account.register')}
                  </b2x.router.Link>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        center={{ center: t('misc.loginRegister') }}
        className="gap-2 bg-nude-100 px-3 text-initial"
        height={46}
        justifyContent="start"
        start={{ center: <Icon name="account" size={20} /> }}
      />
    </b2x.DrawerTriggerItem>
  );
};

const DrawerLocaleItem = () => {
  const { info, locale } = b2x.useAppContext();

  return (
    <b2x.DrawerTriggerItem
      newDrawer={
        <Drawer>
          <div className="px-3 pt-4">
            <b2x.LocaleForm singleColumn />
            {info?.shippingCountries?.length && info.shippingCountries.length > 1 && (
              <div className="mt-3 text-center">
                <p>{t('misc.changeCountryMessage')}</p>
              </div>
            )}
          </div>
        </Drawer>
      }
    >
      <b2x.DrawerItem
        className="small px-3"
        end={{ center: <Icon name="arrow-right-bold" size={10} /> }}
        height={46}
        justifyContent="between"
        start={{
          center: (
            <span>
              <Icon name={'language'} size={16} /> {locale?.code}
            </span>
          ),
        }}
      />
    </b2x.DrawerTriggerItem>
  );
};

export const useMobileNavigationOffcanvas = (props?: MobileNavigationOffcanvasProps) =>
  b2x.useStaticModal(MobileNavigationOffcanvas, 'offcanvas', props);
