import { VideoAsBackgroundContentSectionV2 } from './contentTypes';
import { getItemFromSwitch } from './util';
import { VideoVariant } from './Video';
import { VideoAsBackground } from './VideoAsBackground';

export interface VideoAsBackgroundFromContentV2Props extends VideoAsBackgroundContentSectionV2 {}

export const VideoAsBackgroundFromContentV2 = ({ variants }: VideoAsBackgroundFromContentV2Props) => {
  return (
    <>
      {variants && (
        <VideoAsBackground
          variants={variants.reduce<Array<VideoVariant>>((accumulator, { variant }) => {
            console.log(variant);
            const { height, source, thumbnail, width } = variant ?? {};
            const _source = getItemFromSwitch(source);
            if (width !== undefined && height !== undefined && _source !== undefined) {
              accumulator.push({
                height: height,
                source: {
                  bcom: _source.bcom?.file
                    ? {
                        file: _source.bcom.file,
                      }
                    : undefined,
                  external: _source.external?.file
                    ? {
                        file: _source.external.file,
                      }
                    : undefined,
                  vimeo: _source.vimeo?.id
                    ? {
                        id: _source.vimeo.id,
                        privacyHash: _source.vimeo.privacyHash,
                        title: _source.vimeo.title,
                      }
                    : undefined,
                  youtube: _source.youtube?.id
                    ? {
                        id: _source.youtube.id,
                      }
                    : undefined,
                },
                thumbnail: thumbnail?.url,
                width: width,
              });
            }
            return accumulator;
          }, [])}
        />
      )}
    </>
  );
};

export const isVideoAsBackgroundFromContentValid = (contentSection?: VideoAsBackgroundContentSectionV2) => {
  const variant = contentSection?.variants?.at(0)?.variant;
  const source = getItemFromSwitch(variant?.source);

  return (
    variant?.width &&
    variant.height &&
    source &&
    (source.bcom?.file || source.external?.file || source.youtube?.id || source.vimeo?.id)
  );
};
