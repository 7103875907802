import './CopyC.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface CopyCContentType {
  background?: b2x.contentTypes.ContentSectionSwitch<{
    asset?: b2x.AssetAsBackgroundV2Props;
    color?: string;
  }>;
  columnsContent?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    title?: string;
  }>;
  subtitle?: string;
  title?: string;
}

interface CopyCProps extends CopyCContentType {
  mainComponent?: boolean;
}

export const CopyC = ({ background, columnsContent, mainComponent, subtitle, title }: CopyCProps) => {
  const _background = b2x.getItemFromSwitch(background);
  return (
    <b2x.Div
      className={classnames('copy-c', { 'with-background': _background })}
      paddingY={_background ? undefined : { lg: 5, xs: 3 }}
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      {_background && (
        <b2x.Div
          background={_background.color}
          className="background"
          style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
        >
          {_background.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {..._background.asset} />}
        </b2x.Div>
      )}
      <Container>
        <b2x.Div style={{ overflow: 'hidden', position: 'relative' }}>
          <b2x.Div paddingY={_background ? { lg: 5, xs: 5 } : 0} style={{ position: 'relative' }}>
            {(title || subtitle) && (
              <b2x.Div marginBottom={{ lg: 5, xs: 4 }}>
                <b2x.ConditionalWrapper
                  condition={title !== undefined}
                  wrapper={mainComponent ? <b2x.H1 className="main-title" /> : <b2x.P className="main-title" />}
                >
                  {b2x.formatHtml(title)}
                </b2x.ConditionalWrapper>
                {subtitle && <b2x.Div className="main-subtitle">{b2x.formatHtml(subtitle)}</b2x.Div>}
              </b2x.Div>
            )}
            <b2x.Div paddingX={{ lg: 4, xs: 3 }}>
              <b2x.Row gap={{ lg: 5, xs: 3 }}>
                {columnsContent?.map((item) => (
                  <b2x.Col key={item.contentSectionId} size={{ lg: '', xs: 12 }}>
                    {item.title && (
                      <b2x.Div className="title" marginBottom={3}>
                        {b2x.formatHtml(item.title)}
                      </b2x.Div>
                    )}
                    {item.content && <b2x.Div className="content">{b2x.formatHtml(item.content)}</b2x.Div>}
                  </b2x.Col>
                ))}
              </b2x.Row>
            </b2x.Div>
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
