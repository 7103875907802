import './CopyD.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface CopyDContentType {
  background?: b2x.contentTypes.ContentSectionSwitch<{
    asset?: b2x.AssetAsBackgroundV2Props;
    color?: string;
  }>;
  columnsContent?: b2x.contentTypes.ContentSectionList<{
    asset?: b2x.contentTypes.AssetContentSectionV2;
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    title?: string;
  }>;
  subtitle?: string;
  title?: string;
}

interface CopyDProps extends CopyDContentType {
  mainComponent?: boolean;
}

export const CopyD = ({ background, columnsContent, mainComponent, subtitle, title }: CopyDProps) => {
  const _background = b2x.getItemFromSwitch(background);
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.Div
      className={classnames('copy-d', { 'with-background': _background })}
      paddingY={_background ? undefined : { lg: 5, xs: 3 }}
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      {_background && (
        <b2x.Div
          background={_background.color}
          className="background"
          style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
        >
          {_background.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {..._background.asset} />}
        </b2x.Div>
      )}
      <Container>
        <b2x.Div style={{ overflow: 'hidden', position: 'relative' }}>
          <b2x.Div paddingY={_background ? { lg: 5, xs: 5 } : 0} style={{ position: 'relative' }}>
            {(title || subtitle) && (
              <b2x.Div marginBottom={{ lg: 5, xs: 4 }}>
                <b2x.ConditionalWrapper
                  condition={title !== undefined}
                  wrapper={mainComponent ? <b2x.H1 className="main-title" /> : <b2x.P className="main-title" />}
                >
                  {b2x.formatHtml(title)}
                </b2x.ConditionalWrapper>
                {subtitle && <b2x.Div className="main-subtitle">{b2x.formatHtml(subtitle)}</b2x.Div>}
              </b2x.Div>
            )}
            <b2x.EqualHeight>
              <b2x.Row gap={5} justifyContent={'spaceAround'}>
                {columnsContent?.map((item) => (
                  <b2x.Col key={item.contentSectionId} size={{ lg: 4, md: 6, xs: 12 }}>
                    <b2x.Div display={'flex'} flexDirection={'column'} gap={{ lg: 3, xs: 2 }}>
                      {item.title && (
                        <b2x.Div className="title">
                          <b2x.EqualHeightElement name="title">{b2x.formatHtml(item.title)}</b2x.EqualHeightElement>
                        </b2x.Div>
                      )}
                      {item.content && (
                        <b2x.Div className="content">
                          <b2x.EqualHeightElement name="content">{b2x.formatHtml(item.content)}</b2x.EqualHeightElement>
                        </b2x.Div>
                      )}

                      {item.cta && (
                        <b2x.Div className="cta">
                          <b2x.CtaFromContent
                            {...item.cta}
                            ctaProps={{
                              button: {
                                iconEnd: { name: 'arrow-right-light' },
                              },
                            }}
                          />
                        </b2x.Div>
                      )}
                      {item.asset && (
                        <b2x.Div className="asset">
                          <b2x.ConditionalWrapper
                            condition={item.cta?.to !== undefined}
                            wrapper={
                              <b2x.router.Link
                                to={
                                  item.cta?.to?.href
                                    ? item.cta.to.href
                                    : item.cta?.to?.code && getPagePath(item.cta.to.code)
                                }
                              />
                            }
                          >
                            <b2x.AssetV2 {...item.asset} fluid />
                          </b2x.ConditionalWrapper>
                        </b2x.Div>
                      )}
                    </b2x.Div>
                  </b2x.Col>
                ))}
              </b2x.Row>
            </b2x.EqualHeight>
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
