import './CustomRadio.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface CustomRadioProps {
  className?: string;
  image?: {
    alt: string;
    title: string;
    url: string;
  };
  label: string;
  radio: b2x.RadioProps<string>;
  type: 'image' | 'text' | 'icon';
}

export const CustomRadio = ({ className, image, label, radio, type }: CustomRadioProps) => {
  return (
    <b2x.Radio {...radio} className={className}>
      <div className={classnames('custom-radio', { 'with-icon': type === 'icon' }, { 'with-image': type === 'image' })}>
        {image?.url ? (
          <b2x.Image
            alt={image.alt}
            className="w-100 h-100"
            fluid
            src={{ xs: image.url }}
            style={{ objectFit: 'cover' }}
            title={image.title}
          />
        ) : (
          label
        )}
      </div>
    </b2x.Radio>
  );
};
