import './DownloaderA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface DownloaderAContentType {
  files?: b2x.contentTypes.ContentSectionList<{
    label?: string;
    type?: b2x.contentTypes.ContentSectionSwitch<{
      pdf?: string;
      url?: string;
    }>;
  }>;
  title?: string;
}

interface DownloaderAProps extends DownloaderAContentType {}

export const DownloaderA = ({ files, title }: DownloaderAProps) => {
  return (
    <b2x.Div className="downloader-a" paddingY={5}>
      <Container>
        <b2x.Row gap={3} justifyContent={'center'}>
          <b2x.Col size={12}>
            <b2x.P className="title">{title}</b2x.P>
          </b2x.Col>
          {files?.map((file) => {
            const fileType = b2x.getItemFromSwitch(file.type);
            const fileUrl = fileType?.pdf ? fileType.pdf : fileType?.url ?? undefined;

            return (
              <b2x.Col key={file.contentSectionId} size={'auto'}>
                <b2x.router.Link
                  className={classnames('btn btn-outline-primary', { disabled: !fileUrl })}
                  scrollTop={false}
                  target="_blank"
                  to={fileUrl}
                >
                  {file.label}
                </b2x.router.Link>
              </b2x.Col>
            );
          })}
        </b2x.Row>
      </Container>
    </b2x.Div>
  );
};
