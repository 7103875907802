import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from '../AppContext';

export interface PageProps extends b2x.PageProps {
  backgroundImage?: b2x.contentTypes.ImageContentSectionV1;
  headerCheckout?: boolean;
  hiddenFooter?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  nudeBackground?: boolean;
}

export const Page = ({
  backgroundImage,
  children,
  className,
  headerCheckout = false,
  hiddenFooter = false,
  noPaddingBottom,
  noPaddingTop,
  nudeBackground = false,
  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { setHeaderCheckout, setHiddenFooter } = useAppStaticContext();

  React.useEffect(() => {
    pageReady && setHeaderCheckout(headerCheckout);
    pageReady && setHiddenFooter(hiddenFooter);
  }, [pageReady, headerCheckout, setHeaderCheckout, nudeBackground, hiddenFooter, setHiddenFooter]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100', { 'bg-nude-100': nudeBackground })}>
      <div className={classnames('page h-100 position-relative')}>
        {backgroundImage && (
          <div className="position-absolute h-100 w-100">
            <b2x.ImageAsBackgroundFromContentV1 {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative h-100',
            {
              'pb-3 pb-xl-5': !noPaddingBottom,
              'pt-3 pt-xl-5': !noPaddingTop,
            },
            { 'bg-lighter': nudeBackground }
          )}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
