export const en = {
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: 'Remind me' },
          addShort: { label: 'Remind me' },
        },
      },
      misc: {
        productVariants: 'Size',
      },
    },
    newsletterForm: {
      buttons: {
        submit: {
          label: 'Subscribe',
        },
      }
    }
  },
  magazine: {
    seeAllArticle: 'See all',
    thereAreNoArticles: 'There are no articles for the selected category',
    tile: {
      featured: 'Featured',
      readMore: 'Read more',
    },
  },
  misc: {
    iSenza: 'Isenza',
    newsletterTitle: 'SIGN UP TO OUR NEWSLETTER!',
    outOfStock: 'Out of stock',
    pageBuilder: {
      multiColumnB: {
        readMore: 'Read more',
      },
    },
    storeName: 'BioNike',
    variant: {
      color: 'Color',
      size: 'Size'
    },
  },
  product: {
    freeShippingFrom: 'Free shipping from {{freeShippingFromAmount}}',
    relatedProducts: {
      title: 'Try it with',
    },
  },
  welcome: 'Welcome to BioNike',
};
