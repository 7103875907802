import './Footer.scss';
import './Icon.scss';

import { b2x } from '@b2x/react/src';

import { useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { ListAFromMenu } from './ListAFromMenu';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');
  const footerCompanyMenu = b2x.useMenu('MENU_COMPANY_FOOTER');
  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');

  const { setFooterCopyrightRef } = useAppStaticContext();

  return (
    <footer className="footer">
      <div className="bg-nude">
        <Container>
          <div className="first-row">
            <b2x.Row className="justify-content-center" gap={0}>
              <b2x.Col>
                <b2x.Row>
                  <b2x.Col className="order-lg-2 mt-4 mb-0" size={{ lg: 4, xl: 4, xs: 12 }}>
                    <div className="footer-newsletter text-start p-4">
                      <h2 className="fw-bold text-uppercase">{t('misc.newsletterTitle')}</h2>
                      <div className="small pb-2">{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</div>
                      <NewsletterForm className="mb-4" emailFieldStyle="inputGroup" source="website-footer" />
                    </div>
                    <div className="d-none d-lg-flex justify-content-end my-3 mt-xl-4">
                      {footerCompanyMenu && <ListAFromMenu menu={footerCompanyMenu} />}
                    </div>
                    <div className="d-block d-lg-none pt-4 d-flex justify-content-center">
                      <SocialIcons />
                    </div>
                  </b2x.Col>
                  <b2x.Col className="mt-3 mt-lg-5 mb-4" size={{ lg: 8, xl: 8, xs: 12 }}>
                    <b2x.Row>
                      <b2x.Col>
                        <div className="pb-4">
                          <AccordionFooterMenu id="first-footer-accordion" menu={footerMenu} />
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                    <b2x.Row>
                      <b2x.Col>
                        <div className="d-block d-lg-none">
                          {footerCompanyMenu && <ListAFromMenu menu={footerCompanyMenu} />}
                          <hr className="hr-mobile-footer" />
                        </div>
                        <div className="d-block d-lg-none extra-small">
                          {b2x.formatHtml(footerContent?.body.copyright)}
                        </div>
                        <div className="py-3">
                          <b2x.PaymentIcons />
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                    <b2x.Row>
                      <b2x.Col>
                        <div className="d-none d-lg-block">
                          <div className="py-3 d-flex align-items-center">
                            <h6 className="fw-bold me-3 mb-0 text-uppercase"> {t('misc.followUs')} </h6>
                            <SocialIcons />
                          </div>
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                  </b2x.Col>
                </b2x.Row>
              </b2x.Col>
            </b2x.Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="bottom-footer d-none d-lg-block extra-small py-3" ref={setFooterCopyrightRef}>
          <b2x.Row className="align-items-center">
            <b2x.Col size={{ lg: 4, xs: 12 }}>
              {footerContent && <div>{b2x.formatHtml(footerContent.body.copyright)}</div>}
            </b2x.Col>
            <b2x.Col size={{ lg: 8, xs: 12 }}>
              <div className="d-flex justify-content-end text-uppercase">
                {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
              </div>
            </b2x.Col>
          </b2x.Row>
        </div>
      </Container>
    </footer>
  );
};

interface AccordionFooterMenuProps {
  id: string;
  menu?: b2x.MenuApiDto;
}
const AccordionFooterMenu = ({ id, menu }: AccordionFooterMenuProps) => {
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();
  return (
    <b2x.Accordion
      columnLayoutFrom="lg"
      id={id}
      itemBodyClassName="small pb-3"
      itemButtonClassName="itemButtonClassName fw-bold mb-lg-1 p-3 p-lg-0"
      itemClassName="itemClassName"
    >
      {menu?.children.map(
        (column) =>
          column.label && (
            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.code} title={column.label}>
              {column.subTitle && <div>{column.subTitle}</div>}
              {column.children.map((li) =>
                li.code === 'SITE_CCN_AREA' ? (
                  session?.userLogged ? (
                    li.children.map(
                      (subLi) =>
                        subLi.link && (
                          <div key={subLi.code}>
                            <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={subLi.link}>
                              {subLi.label}
                            </b2x.router.Link>
                          </div>
                        )
                    )
                  ) : (
                    <div key={li.code}>
                      <b2x.BlankButton className="d-block py-1" onClick={showAccountOffcanvas}>
                        {t('account.login')}
                      </b2x.BlankButton>
                    </div>
                  )
                ) : (
                  li.link && (
                    <div key={li.code}>
                      {li.image ? (
                        <div className="pt-2">
                          <b2x.router.Link className={'d-inline-block'} to={li.link}>
                            <Icon name="gift-card" size={40} />
                          </b2x.router.Link>
                          {/*
                          <b2x.router.Link className={'d-inline-block'} to={li.link}>
                            // <b2x.Image {...li.image} fluid width={100} />
                          </b2x.router.Link>
                          */}
                        </div>
                      ) : (
                        <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={li.link}>
                          {li.label}
                        </b2x.router.Link>
                      )}
                    </div>
                  )
                )
              )}
            </b2x.AccordionItem>
          )
      )}
    </b2x.Accordion>
  );
};
