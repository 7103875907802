import { ProductApiDto } from '@b2x/storefront-api-js-client/src/';
import { GetProductOptions } from '@b2x/storefront-api-js-client/src/products';
import React from 'react';

import { ApiRequestConfig } from './api/useApiRequest';
import { useProductsApi } from './api/useProductsApi';
import { appConfig } from './config';
import { useStable } from './util';

export type UseProductByIdResult<ContentType> = ProductApiDto<ContentType> | undefined;

export const useProductById = <ContentType>(
  id: string,
  options?: GetProductOptions,
  config?: ApiRequestConfig
): UseProductByIdResult<ContentType> => {
  const [product, setProduct] = React.useState<ProductApiDto<ContentType>>();

  const { getProduct } = useProductsApi();

  const { populate = appConfig.api?.productTilePopulate, ...otherOptions } = options ?? {};

  const otherStableOptions = useStable(otherOptions);
  const stablePopulate = useStable(populate);
  const stableConfig = useStable(config);

  React.useEffect(() => {
    getProduct<ContentType>(id, { populate: stablePopulate, ...otherStableOptions }, stableConfig).then((response) => {
      setProduct(response.data);
    });
  }, [id, stablePopulate, otherStableOptions, stableConfig, getProduct]);

  return product;
};
