import { b2x } from '@b2x/react/src';

import { ThankYouPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { MessageBoxPage } from './MessageBoxPage';

export interface ThankYouPageProps {}

export const ThankYouPage = (props: ThankYouPageProps) => {
  const content = b2x.useContent<ThankYouPageContentType>('THANKYOU_PAGE_CONTENT');

  const { bankAccountDetails, order, session } = b2x.useThankYouPage({});

  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-end">
      <div className="px-3 py-5">
        <h1 className="text-uppercase fw-medium">{b2x.formatHtml(content?.body.title)}</h1>
        <p className="py-1"> {b2x.formatHtml(content?.body.subTitle)}</p>
        {order?.paymentMethod?.type === 'BANK_TRANSFER' && (
          <>
            <div className="small fw-light">{b2x.formatHtml(content?.body.bankTransfer?.pre)}</div>
            <dl className="row small fw-light">
              <dt className="col-6 fw-medium text-uppercase text-end">{t('misc.amount')}</dt>
              <dd className="col-6 text-start">{b2x.formatCurrency(order.totalCost)}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.ownerName')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.ownerName}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.bankName')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.bankName}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.ibanCode')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.ibanCode}</dd>
              <dt className="col-6 fw-medium text-uppercase text-end">{t('bankAccountDetails.bicSwiftCode')}</dt>
              <dd className="col-6 text-start">{bankAccountDetails?.bicSwiftCode}</dd>
            </dl>
            <div className="small fw-light">{b2x.formatHtml(content?.body.bankTransfer?.post)}</div>
          </>
        )}
        <h6 className="mb-5 mt-5">
          {t('misc.orderCode')}: {order?.code}
        </h6>
        {session?.userLogged && (
          <b2x.router.Link className="text-reset fw-bold" to="/">
            {b2x.formatHtml(content?.body.cta?.label)}
          </b2x.router.Link>
        )}
      </div>
      {!session?.userLogged && (
        <div className="bg-nude-300 px-2 py-4">
          <h6 className="mb-4">{b2x.formatHtml(content?.body.registerSection?.content)}</h6>
          <b2x.Row gap={3}>
            <b2x.Col size={12}>
              <b2x.router.Link className="btn btn-primary" to="/register">
                {b2x.formatHtml(content?.body.registerSection?.ctaContinue?.label)}
              </b2x.router.Link>
            </b2x.Col>
            <b2x.Col size={12}>
              <b2x.router.Link className="text-reset fw-bold" to="/">
                {content?.body.registerSection?.ctaCancel?.label}
              </b2x.router.Link>
            </b2x.Col>
          </b2x.Row>
        </div>
      )}
    </MessageBoxPage>
  );
};
