import './MagazineCategoryPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';
import { MagazineArticlePageContentType, MagazineCategoryPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { MagazineTile } from '../MagazineTile';
import { AssetA } from '../pagebuilder/AssetA';
import { CopyA } from '../pagebuilder/CopyA';
import { DoubleColumnsA } from '../pagebuilder/DoubleColumnsA';
import { HeaderA } from '../pagebuilder/HeaderA';
import { SingleColumnA } from '../pagebuilder/SingleColumnA';
import { Page } from './Page';

export interface MagazineCategoryPageProps {}

export const MagazineCategoryPage = (props: MagazineCategoryPageProps) => {
  const page = b2x.usePage<MagazineCategoryPageContentType>();
  const featuredArticle = b2x.useContent<MagazineArticlePageContentType>(page?.content?.body.featuredArticleCode);

  const { searchContentsResult } = b2x.useContentsSearch<
    MagazineCategoryPageContentType,
    MagazineArticlePageContentType
  >({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: true,
        content: true,
      },
    },
    populate: { items: { pages: true } },
    type: 'MAGAZINE_ARTICLE_PAGE_CONTENT_TYPE',
  });

  return (
    <Page className="magazine-category-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <b2x.Div display={'flex'} flexDirection={'column'} gap={{ lg: 5, xs: 3 }}>
        <MagazineContentBuilderSection page={page} />
        {/* {featuredArticle && searchContentsResult?.pageNumber === 1 && ( */}
        {featuredArticle && <MagazineFeaturedTileSection article={featuredArticle} />}
        <MagazineNavigationSection menuCode="MENU_MAGAZINE_CATEGORIES" />
        {searchContentsResult && <MagazineTilesSection searchContentsResult={searchContentsResult} />}
      </b2x.Div>
    </Page>
  );
};

interface MagazineContentBuilderSectionProps {
  page: b2x.PageApiDto<MagazineCategoryPageContentType> | undefined;
}

const MagazineContentBuilderSection = ({ page }: MagazineContentBuilderSectionProps) => {
  const content = page?.content;
  return (
    <b2x.Section className="magazine-content-builder-section">
      {content?.body.sections?.map((section) => {
        let component = null;
        const sectionComponent = b2x.getItemFromSwitch(section.component);

        if (sectionComponent?.headerA) {
          component = <HeaderA {...sectionComponent.headerA} />;
        }

        if (sectionComponent?.assetA) {
          component = <AssetA {...sectionComponent.assetA} />;
        }

        if (sectionComponent?.copyA) {
          component = <CopyA {...sectionComponent.copyA} />;
        }

        if (sectionComponent?.singleColumnA) {
          component = <SingleColumnA {...sectionComponent.singleColumnA} />;
        }

        if (sectionComponent?.doubleColumnsA) {
          component = <DoubleColumnsA {...sectionComponent.doubleColumnsA} />;
        }

        return (
          <React.Fragment key={section.contentSectionId}>
            {section.options?.showSection && (
              <b2x.Section
                className={classnames('component-section')}
                marginBottom={{
                  lg: section.options.marginBottom?.lg as b2x.MarginSize,
                  md: section.options.marginBottom?.md as b2x.MarginSize,
                  sm: section.options.marginBottom?.sm as b2x.MarginSize,
                  xl: section.options.marginBottom?.xl as b2x.MarginSize,
                  xs: section.options.marginBottom?.xs as b2x.MarginSize,
                  xxl: section.options.marginBottom?.xxl as b2x.MarginSize,
                }}
              >
                {component}
              </b2x.Section>
            )}
          </React.Fragment>
        );
      })}
    </b2x.Section>
  );
};

interface MagazineFeaturedTileSectionProps {
  article: b2x.ContentApiDto<MagazineArticlePageContentType>;
}

const MagazineFeaturedTileSection = ({ article }: MagazineFeaturedTileSectionProps) => {
  return (
    <b2x.Section className="magazine-featured-tile-section mb-4 mb-lg-0">
      <Container>
        <b2x.EqualHeight>
          <MagazineTile article={article} featured />
        </b2x.EqualHeight>
      </Container>
    </b2x.Section>
  );
};

interface MagazineNavigationSectionProps {
  menuCode: string;
}

const MagazineNavigationSection = ({ menuCode }: MagazineNavigationSectionProps) => {
  const location = b2x.router.useLocation();

  const [swiper, setSwiper] = React.useState<b2x.SwiperClass>();

  // Recupero il menu
  const menu = b2x.useMenu(menuCode);

  // Fondo al primo livello i children
  const _menu = menu?.children && [menu, ...menu.children];
  // Per evitare possibili ricorsioni rimuovo i children dalle voci
  const navigation = _menu?.map(({ children, ...rest }) => rest);

  // Con questa useEffect imposto nello swiper la posizione della slide corrente
  React.useEffect(() => {
    const position = navigation?.findIndex((item) => item.link === location.pathname);
    swiper && position && swiper.slideTo(position);
  }, [location.pathname, navigation, swiper]);

  return (
    <b2x.Section className="magazine-navigation-section mb-3 mb-lg-0">
      <Container>
        <b2x.SwiperContext>
          <b2x.Swiper
            onSwiper={setSwiper}
            slides={navigation?.map((category, index) => (
              <b2x.router.NavLink className={'btn btn-outline-primary'} end key={category.code} to={category.link}>
                {index > 0 ? category.label : t('magazine.seeAllArticle')}
              </b2x.router.NavLink>
            ))}
            slidesPerView={'auto'}
            spaceBetween={8}
          />
        </b2x.SwiperContext>
      </Container>
    </b2x.Section>
  );
};

interface MagazineTilesSectionProps {
  searchContentsResult: b2x.SearchContentsApiDto<MagazineArticlePageContentType>;
}

const MagazineTilesSection = ({ searchContentsResult }: MagazineTilesSectionProps) => {
  return (
    <b2x.Section className="magazine-tiles-section">
      <Container>
        <b2x.Div display={'flex'} flexDirection={'column'} gap={5}>
          {searchContentsResult.itemsCount === 0 && t('magazine.thereAreNoArticles')}
          {searchContentsResult.itemsCount && searchContentsResult.itemsCount > 1 ? (
            <b2x.EqualHeight>
              <b2x.Row gapX={3} gapY={{ lg: 5, xs: 4 }}>
                {searchContentsResult.items?.map((article, index) => (
                  <b2x.Col key={article.id} size={{ lg: 4, xs: 12 }}>
                    <MagazineTile article={article} index={index + 1} />
                  </b2x.Col>
                ))}
              </b2x.Row>
              <b2x.Div display="flex" justifyContent="center">
                <b2x.Pagination
                  currentPage={searchContentsResult.pageNumber}
                  pageOffset={2}
                  showDots
                  singleStepNavigation
                  totalPages={searchContentsResult.pagesCount}
                />
              </b2x.Div>
            </b2x.EqualHeight>
          ) : undefined}
        </b2x.Div>
      </Container>
    </b2x.Section>
  );
};
