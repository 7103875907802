import './HeaderA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface HeaderAContentType {
  asset?: b2x.contentTypes.AssetContentSectionV2;
  className?: string;
  copy?: {
    cta?: b2x.contentTypes.CtaContentSection;
    disclaimer?: string;
    options?: {
      alignment?: string;
      size?: string;
      textColor?: string;
    };
    subtitle?: string;
    title?: string;
  };
  options?: {
    background?: string;
    enableBreadcrumbs?: boolean;
  };
}

interface HeaderAProps extends HeaderAContentType {
  mainComponent?: boolean;
}

export const HeaderA = ({ asset, className, copy, mainComponent, options }: HeaderAProps) => {
  const alignment =
    copy?.options?.alignment === 'end' ? 'end' : copy?.options?.alignment === 'center' ? 'center' : 'start';

  const size: b2x.ColSize =
    copy?.options?.size === 'small'
      ? 4
      : copy?.options?.size === 'large'
      ? 8
      : copy?.options?.size === 'extra-large'
      ? 12
      : 6;
  return (
    <b2x.Div className={classnames('header-a', className)}>
      {options?.enableBreadcrumbs && (
        <b2x.Div className="breadcrumb-container" paddingY={3} style={{ zIndex: 10 }}>
          <Container>
            <b2x.Breadcrumb />
          </Container>
        </b2x.Div>
      )}
      <b2x.Div background={options?.background} className="main-container">
        {asset?.type && asset.type.length > 0 && (
          <b2x.Div className="asset-container" style={{ zIndex: 5 }} textAlign={'center'}>
            <b2x.AssetV2 {...asset} fluid />
          </b2x.Div>
        )}
        {(copy?.title || copy?.subtitle || copy?.cta?.label || copy?.disclaimer) && (
          <b2x.Div
            className="copy-container position-relative gap-3"
            display="flex"
            justifyContent={'center'}
            style={{ color: copy.options?.textColor ?? undefined, zIndex: 10 }}
          >
            <Container>
              <b2x.Row justifyContent={{ lg: alignment, xs: 'center' }}>
                <b2x.Col size={{ lg: size, xs: 12 }}>
                  <b2x.Div className="main-text-container" textAlign={{ lg: alignment, xs: 'center' }}>
                    <b2x.Div>
                      <b2x.ConditionalWrapper
                        condition={copy.title !== undefined}
                        wrapper={
                          mainComponent ? (
                            <b2x.H1 className="title" marginBottom={copy.subtitle ? { lg: 3, xs: 2 } : 0} />
                          ) : (
                            <b2x.P className="title" marginBottom={copy.subtitle ? { lg: 3, xs: 2 } : 0} />
                          )
                        }
                      >
                        {b2x.formatHtml(copy.title)}
                      </b2x.ConditionalWrapper>
                      {copy.subtitle && <b2x.P className="subtitle">{b2x.formatHtml(copy.subtitle)}</b2x.P>}
                      {copy.cta && <b2x.CtaFromContent {...copy.cta} />}
                    </b2x.Div>
                  </b2x.Div>
                </b2x.Col>
              </b2x.Row>
            </Container>
            <b2x.Div className="disclaimer">
              <Container>
                <b2x.Row justifyContent={{ lg: alignment, xs: 'center' }}>
                  <b2x.Col size={{ lg: size, xs: 12 }}>
                    <b2x.Div textAlign={{ lg: alignment, xs: 'center' }}>{b2x.formatHtml(copy.disclaimer)}</b2x.Div>
                  </b2x.Col>
                </b2x.Row>
              </Container>
            </b2x.Div>
          </b2x.Div>
        )}
      </b2x.Div>
    </b2x.Div>
  );
};
