import './CopyA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';

export interface CopyAContentType {
  background?: b2x.contentTypes.ContentSectionSwitch<{
    asset?: b2x.AssetAsBackgroundV2Props;
    color?: string;
  }>;
  content?: string;
  textAlign?: string;
  title?: string;
}

interface CopyAProps extends CopyAContentType {
  mainComponent?: boolean;
}

export const CopyA = ({ background, content, mainComponent, textAlign, title }: CopyAProps) => {
  const _background = b2x.getItemFromSwitch(background);

  const _textAlign: b2x.TextAlign =
    textAlign === 'start' ? 'start' : textAlign === 'center' ? 'center' : textAlign === 'end' ? 'end' : 'start';
  return (
    <b2x.Div
      className={classnames('copy-a', { 'with-background': _background })}
      paddingY={_background ? undefined : { lg: 5, xs: 3 }}
    >
      <Container>
        <b2x.Div style={{ overflow: 'hidden', position: 'relative' }}>
          {_background && (
            <b2x.Div
              background={_background.color}
              className="background"
              style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
            >
              {_background.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {..._background.asset} />}
            </b2x.Div>
          )}
          <b2x.Div
            paddingX={_background ? { lg: 0, xs: 3 } : 0}
            paddingY={_background ? { lg: 5, xs: 3 } : 0}
            style={{ position: 'relative' }}
          >
            <b2x.Row justifyContent={'center'}>
              <b2x.Col size={{ lg: 10, xs: 12 }}>
                <b2x.Div className={classnames({ 'content-container': _background })} padding={_background ? 5 : 0}>
                  <b2x.ConditionalWrapper
                    condition={title !== undefined}
                    wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
                  >
                    {b2x.formatHtml(title)}
                  </b2x.ConditionalWrapper>
                  {content && (
                    <b2x.Div className="content" textAlign={_textAlign}>
                      {b2x.formatHtml(content)}
                    </b2x.Div>
                  )}
                </b2x.Div>
              </b2x.Col>
            </b2x.Row>
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
