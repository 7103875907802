import './SingleColumnA.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';

export interface SingleColumnAContentType {
  asset?: b2x.contentTypes.AssetContentSectionV2;
  copy?: {
    alignment?: string;
    background?: {
      desktop?: string;
      mobile?: string;
    };
    cta?: b2x.contentTypes.CtaContentSection;
    size?: string;
    subTitle?: string;
    title?: string;
  };
}

interface SingleColumnAProps extends SingleColumnAContentType {
  mainComponent?: boolean;
}

export const SingleColumnA = ({ asset, copy, mainComponent }: SingleColumnAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  const alignment = copy?.alignment === 'end' ? 'end' : copy?.alignment === 'center' ? 'center' : 'start';

  const size: b2x.ColSize =
    copy?.size === 'small' ? 4 : copy?.size === 'large' ? 8 : copy?.size === 'extra-large' ? 12 : 6;

  return (
    <b2x.Div className="single-column-a" display="grid" style={{ position: 'relative' }}>
      {asset?.type && (
        <b2x.Div className="asset-container">
          <b2x.ConditionalWrapper
            condition={copy?.cta !== undefined}
            wrapper={
              <b2x.router.Link
                to={copy?.cta?.to?.href ? copy.cta.to.href : copy?.cta?.to?.code && getPagePath(copy.cta.to.code)}
              />
            }
          >
            <b2x.AssetV2 {...asset} fluid />
          </b2x.ConditionalWrapper>
        </b2x.Div>
      )}
      <b2x.Div
        alignItems={'center'}
        className="copy-container"
        display="grid"
        style={{ background: isMobile ? copy?.background?.mobile : undefined, gridRow: isMobile ? 2 : undefined }}
      >
        <Container>
          <b2x.Row justifyContent={{ lg: alignment, xs: 'center' }}>
            <b2x.Col size={{ lg: size, xs: 12 }}>
              <b2x.Div
                className="main-text-container"
                paddingY={{ lg: 0, xs: 3 }}
                textAlign={{ lg: alignment, xs: 'center' }}
              >
                <b2x.Div
                  background={!isMobile ? copy?.background?.desktop : undefined}
                  padding={{ lg: !isMobile && copy?.background?.desktop ? 5 : 0 }}
                >
                  <b2x.ConditionalWrapper
                    condition={copy?.title !== undefined}
                    wrapper={
                      mainComponent ? (
                        <b2x.H1 className="title" marginBottom={{ lg: 3, xs: 2 }} />
                      ) : (
                        <b2x.P className="title" marginBottom={{ lg: 3, xs: 2 }} />
                      )
                    }
                  >
                    {b2x.formatHtml(copy?.title)}
                  </b2x.ConditionalWrapper>
                  {copy?.subTitle && (
                    <b2x.P className="subtitle" marginBottom={{ lg: 4, xs: 2 }}>
                      {b2x.formatHtml(copy.subTitle)}
                    </b2x.P>
                  )}
                  {copy?.cta && <b2x.CtaFromContent {...copy.cta} />}
                </b2x.Div>
              </b2x.Div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </b2x.Div>
    </b2x.Div>
  );
};
