import './ProductsSliderSingleColumnA.scss';

import { b2x } from '@b2x/react/src';

import { Button } from '../Button';
import { Container } from '../Container';
import { isIconName } from '../Icon';

export interface ProductsSliderSingleColumnAContentType {
  cta?: b2x.contentTypes.CtaContentSection;
  head?: {
    preTitle?: string;
    subTitle?: string;
    title?: string;
  };
  ids?: Array<{ id?: string }>;
  mainComponent?: boolean;
  mobileCtaVariant?: string;
}

export interface ProductsSliderSingleColumnAProps extends ProductsSliderSingleColumnAContentType {}

export const ProductsSliderSingleColumnA = ({
  cta,
  head,
  ids,
  mainComponent,
  mobileCtaVariant,
}: ProductsSliderSingleColumnAProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  return (
    <b2x.Div className="products-slider-single-column-a" paddingY={{ lg: 5, xs: 3 }}>
      <Container>
        {(head?.preTitle || head?.title || head?.subTitle) && (
          <b2x.Div
            className="component-head"
            display="flex"
            flexDirection="column"
            gap={3}
            marginBottom={{ lg: 5, xs: 3 }}
            textAlign="center"
          >
            {head.preTitle && (
              <b2x.P className="pre-title" marginBottom={0}>
                {head.preTitle}
              </b2x.P>
            )}
            <b2x.Row justifyContent="center">
              <b2x.Col size={{ md: 8, xs: 12 }}>
                <b2x.ConditionalWrapper
                  condition={head.title !== undefined}
                  wrapper={
                    mainComponent ? (
                      <b2x.H1 className="title" marginBottom={0} />
                    ) : (
                      <b2x.P className="title" marginBottom={0} />
                    )
                  }
                >
                  {head.title}
                </b2x.ConditionalWrapper>
              </b2x.Col>
            </b2x.Row>
            {head.subTitle && (
              <b2x.P className="subtitle" marginBottom={0}>
                {head.subTitle}
              </b2x.P>
            )}
            {!isMobile && cta?.label && (
              <b2x.Div marginEnd={0} marginStart="auto">
                <b2x.CtaFromContent {...cta} />
              </b2x.Div>
            )}
          </b2x.Div>
        )}
        {ids?.length && ids.length > 0 && (
          <b2x.Div
            className="products-swiper-container"
            marginBottom={cta?.label ? 3 : undefined}
            paddingX={{ lg: 5, xs: 0 }}
          >
            <b2x.ProductsByIds ids={ids.map(({ id }) => id)}>
              {(products) => (
                <b2x.EqualHeight>
                  <b2x.Listing name="Landing template B products" products={products}>
                    <b2x.SwiperContext>
                      {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
                        <>
                          <b2x.Swiper
                            breakpoints={{
                              lg: {
                                slidesPerView: 4,
                              },
                              md: {
                                slidesPerView: 2.3,
                              },
                              xs: {
                                slidesPerView: 1.2,
                              },
                            }}
                            centerInsufficientSlides
                            navigation={{ custom: true }}
                            slides={products?.map((product) => (
                              <b2x.ProductTile key={product.id} product={product} />
                            ))}
                            spaceBetween={10}
                            watchSlidesProgress
                          />
                          <b2x.Div
                            className="position-absolute w-100 top-0 start-0"
                            display={{ lg: 'block', xs: 'none' }}
                          >
                            <b2x.EqualHeightElement name={'product-media-container'}>
                              {b2x.appConfig.icons?.slider?.arrow?.left &&
                                isIconName(b2x.appConfig.icons.slider.arrow.left) && (
                                  <b2x.Div display={swiper?.isBeginning ? 'none' : 'block'}>
                                    <Button
                                      className="swiper-arrow swiper-arrow-left"
                                      iconStart={{ name: b2x.appConfig.icons.slider.arrow.left, size: 34 }}
                                      innerRef={navigationPrevElRef}
                                      variant="blank"
                                    />
                                  </b2x.Div>
                                )}
                              {b2x.appConfig.icons?.slider?.arrow?.right &&
                                isIconName(b2x.appConfig.icons.slider.arrow.right) && (
                                  <b2x.Div display={swiper?.isEnd ? 'none' : 'block'}>
                                    <Button
                                      className="swiper-arrow swiper-arrow-right"
                                      iconStart={{ name: b2x.appConfig.icons.slider.arrow.right, size: 34 }}
                                      innerRef={navigationNextElRef}
                                      variant="blank"
                                    />
                                  </b2x.Div>
                                )}
                            </b2x.EqualHeightElement>
                          </b2x.Div>
                        </>
                      )}
                    </b2x.SwiperContext>
                  </b2x.Listing>
                </b2x.EqualHeight>
              )}
            </b2x.ProductsByIds>
          </b2x.Div>
        )}
        {cta?.label && (
          <b2x.Div display={{ lg: 'none', xs: 'block' }} textAlign={'center'}>
            <b2x.CtaFromContent {...cta} variant={mobileCtaVariant} />
          </b2x.Div>
        )}
      </Container>
    </b2x.Div>
  );
};
