import { b2x } from '@b2x/react/src';

import { ErrorPageContentType } from '../contentTypes';
import { MessageBoxPage } from './MessageBoxPage';
export interface ErrorPageProps {
  contentCode: string;
  errorCode?: number;
}

export const ErrorPage = ({ contentCode, errorCode }: ErrorPageProps) => {
  const content = b2x.useContent<ErrorPageContentType>(contentCode);
  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-center">
      <div className="px-2 py-5">
        {errorCode && <p className="h1 fw-medium mb-4">{errorCode}</p>}
        <h1 className="h5 fw-bold">{b2x.formatHtml(content?.body.title)}</h1>
        <p>{b2x.formatHtml(content?.body.content)}</p>
        {content?.body.cta && (
          <b2x.DeprecatedCta className="btn btn-primary" cta={content.body.cta}>
            {content.body.cta.label}
          </b2x.DeprecatedCta>
        )}
      </div>
    </MessageBoxPage>
  );
};
