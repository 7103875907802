import './MagazineTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { MagazineArticlePageContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';

export interface MagazineTileProps {
  article: b2x.ContentApiDto<MagazineArticlePageContentType>;
  featured?: boolean;
  index?: number;
}

export const MagazineTile = ({ article, featured, index }: MagazineTileProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const itemPerLine = b2x.untilBreakpoint('md', currentBreakpoint) ? 1 : 3;

  const equalHeightLine = featured ? 'featured' : index && Math.ceil(index / itemPerLine);

  return (
    <b2x.Div className={classnames('magazine-tile', { featured: featured })}>
      <b2x.Row gap={3}>
        <b2x.Col size={{ lg: featured ? 8 : 12, xs: 12 }}>
          {article.body.abstract?.image && (
            <b2x.router.Link to={article.url}>
              <b2x.EqualHeightElement name={`magazine-tile-image-${equalHeightLine}`}>
                <b2x.ImageFromContentV2 className="image" {...article.body.abstract.image} />
              </b2x.EqualHeightElement>
            </b2x.router.Link>
          )}
        </b2x.Col>
        <b2x.Col alignItems={'center'} display={'flex'} size={{ lg: featured ? 4 : 12, xs: 12 }}>
          <b2x.Div
            display={'flex'}
            flexDirection={'column'}
            gap={{ lg: 3, xs: 2 }}
            paddingStart={{ lg: featured ? 3 : 0 }}
          >
            {featured && <span className="text-uppercase">{t('magazine.tile.featured')}</span>}
            {article.body.title && (
              <b2x.EqualHeightElement name={`magazine-tile-title-${equalHeightLine}`}>
                <b2x.P className="title fw-bold" margin={0}>
                  <b2x.router.Link to={article.url}>{article.body.title}</b2x.router.Link>
                </b2x.P>
              </b2x.EqualHeightElement>
            )}
            {article.body.abstract?.content && (
              <b2x.EqualHeightElement name={`magazine-tile-content-${equalHeightLine}`}>
                <b2x.Div className="content">{b2x.formatHtml(article.body.abstract.content)}</b2x.Div>
              </b2x.EqualHeightElement>
            )}
            <b2x.Div>
              <b2x.router.Link className="btn btn-link d-flex align-items-center gap-1" to={article.url}>
                {t('magazine.tile.readMore')} <Icon name="arrow-right-light" size={10} />
              </b2x.router.Link>
            </b2x.Div>
          </b2x.Div>
        </b2x.Col>
      </b2x.Row>
    </b2x.Div>
  );
};
