import { AssetContentSectionV2 } from './contentTypes';
import { ImageFromContentV2 } from './ImageFromContentV2';
import { getItemFromSwitch } from './util';
import { VideoFromContentV2 } from './VideoFromContentV2';

export interface AssetPropsV2 extends AssetContentSectionV2 {
  className?: string;
  fluid?: boolean;
}

export const AssetV2 = ({ className, fluid, type }: AssetPropsV2) => {
  const asset = getItemFromSwitch(type);

  return (
    <>
      {asset?.image ? (
        <ImageFromContentV2 {...asset.image} className={className} fluid={fluid} />
      ) : (
        asset?.video && <VideoFromContentV2 {...asset.video} className={className} />
      )}
    </>
  );
};
