import './IconsB.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';

export interface IconsBContentType {
  icons?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    title?: string;
    type?: b2x.contentTypes.ContentSectionSwitch<{
      image?: b2x.contentTypes.ImageContentSectionV2;
      svg?: string;
    }>;
  }>;
}

interface IconsBProps extends IconsBContentType {}

export const IconsB = ({ icons }: IconsBProps) => {
  return (
    <b2x.Div className="icons-b" paddingY={{ lg: 5, xs: 3 }}>
      {icons?.length && (
        <Container>
          <b2x.Row gap={3} justifyContent={'center'}>
            {icons.map(({ content, contentSectionId, title, type }) => {
              const icon = b2x.getItemFromSwitch(type);

              return (
                icon?.svg !== 'senza-glutine' && (
                  <b2x.Col key={contentSectionId} size={{ lg: '', xs: 6 }}>
                    <b2x.Div textAlign={'center'}>
                      {icon?.svg && isIconName(icon.svg) ? (
                        <b2x.Div marginBottom={3}>
                          <Icon name={icon.svg} size={50} />
                        </b2x.Div>
                      ) : (
                        icon?.image && (
                          <b2x.Div className="asset" marginBottom={3}>
                            <b2x.ImageFromContentV2 width={50} {...icon.image} fluid />
                          </b2x.Div>
                        )
                      )}
                      <b2x.Div className="copy">
                        {title && <b2x.P className="title">{title}</b2x.P>}
                        {content && (
                          <b2x.P className="content" margin={0}>
                            {content}
                          </b2x.P>
                        )}
                      </b2x.Div>
                    </b2x.Div>
                  </b2x.Col>
                )
              );
            })}
          </b2x.Row>
        </Container>
      )}
    </b2x.Div>
  );
};
