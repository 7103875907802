import './IconsA.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';

export interface IconsAContentType {
  body?: {
    icons?: b2x.contentTypes.ContentSectionList<{
      title?: string;
      type?: b2x.contentTypes.ContentSectionSwitch<{
        image?: b2x.contentTypes.ImageContentSectionV2;
        svg?: string;
      }>;
    }>;
  };
  foot?: {
    cta?: b2x.contentTypes.CtaContentSection;
  };
  head?: {
    content?: string;
    title?: string;
  };
  options?: {
    innerBackground?: b2x.contentTypes.ContentSectionSwitch<{
      asset?: b2x.AssetAsBackgroundV2Props;
      color?: string;
      image?: b2x.contentTypes.ImageContentSectionV2;
    }>;
    outerBackground?: b2x.contentTypes.ContentSectionSwitch<{
      asset?: b2x.AssetAsBackgroundV2Props;
      color?: string;
      image?: b2x.contentTypes.ImageContentSectionV2;
    }>;
  };
}

interface IconsAProps extends IconsAContentType {
  mainComponent?: boolean;
}

export const IconsA = ({ body, foot, head, mainComponent, options }: IconsAProps) => {
  const outerBackground = b2x.getItemFromSwitch(options?.outerBackground);
  const innerBackground = b2x.getItemFromSwitch(options?.innerBackground);

  return (
    <b2x.Div background={outerBackground?.color} className={'icons-a'} style={{ position: 'relative' }}>
      <Container style={{ position: 'relative' }}>
        <b2x.Div
          background={innerBackground?.color}
          paddingY={{ lg: 5, xs: 3 }}
          style={{ position: 'relative', zIndex: 2 }}
          textAlign="center"
        >
          {(head?.title || head?.content) && (
            <b2x.Div className="component-head" marginBottom={{ lg: 5, xs: 4 }}>
              <b2x.Row justifyContent={'center'}>
                <b2x.Col size={{ lg: 8, xs: 12 }}>
                  <b2x.ConditionalWrapper
                    condition={head.title !== undefined}
                    wrapper={
                      mainComponent ? <b2x.H1 className="title" margin={3} /> : <b2x.P className="title" margin={3} />
                    }
                  >
                    {b2x.formatHtml(head.title)}
                  </b2x.ConditionalWrapper>
                  {head.content && (
                    <b2x.P className="content" margin={0}>
                      {b2x.formatHtml(head.content)}
                    </b2x.P>
                  )}
                </b2x.Col>
              </b2x.Row>
            </b2x.Div>
          )}
          {body?.icons?.length && (
            <b2x.Div className="component-body" marginBottom={foot?.cta?.label ? { lg: 5, xs: 4 } : undefined}>
              <b2x.Row gap={3} justifyContent={'center'}>
                {body.icons.map((icon) => {
                  const iconType = b2x.getItemFromSwitch(icon.type);

                  return (
                    iconType?.svg !== 'senza-glutine' && (
                      <b2x.Col key={icon.contentSectionId} size={{ lg: 3, xs: 6 }}>
                        {iconType?.svg && isIconName(iconType.svg) ? (
                          <b2x.Div marginBottom={icon.title ? 3 : 0}>
                            <Icon name={iconType.svg} size={50} />
                          </b2x.Div>
                        ) : (
                          iconType?.image && <b2x.ImageFromContentV2 {...iconType.image} fluid style={{ width: 50 }} />
                        )}
                        {icon.title && (
                          <b2x.P className="icon-text" marginBottom={0} marginTop={3}>
                            {icon.title}
                          </b2x.P>
                        )}
                      </b2x.Col>
                    )
                  );
                })}
              </b2x.Row>
            </b2x.Div>
          )}
          {foot?.cta?.label && (
            <b2x.Div className="component-foot">
              <b2x.CtaFromContent {...foot.cta} />
            </b2x.Div>
          )}
        </b2x.Div>
        {(innerBackground?.image || innerBackground?.asset) && (
          <b2x.Div style={{ height: '100%', inset: 0, position: 'absolute', width: '100%', zIndex: 0 }}>
            {innerBackground.image && <b2x.ImageAsBackgroundFromContentV2 {...innerBackground.image} />}
            {innerBackground.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {...innerBackground.asset} />}
          </b2x.Div>
        )}
      </Container>
      {(outerBackground?.image || outerBackground?.asset) && (
        <b2x.Div style={{ height: '100%', inset: 0, position: 'absolute', width: '100%', zIndex: 0 }}>
          {outerBackground.image && <b2x.ImageAsBackgroundFromContentV2 {...outerBackground.image} />}
          {outerBackground.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {...outerBackground.asset} />}
        </b2x.Div>
      )}
    </b2x.Div>
  );
};
