import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: 'Avvisami' },
          addShort: { label: 'Avvisami' },
        },
      },
    },
    newsletterForm: {
      buttons: {
        submit: {
          label: 'Iscriviti',
        },
      }
    }
  },
  magazine: {
    seeAllArticle: 'Vedi tutti',
    thereAreNoArticles: 'Non ci sono articoli per la categoria selezionata',
    tile: {
      featured: 'In evidenza',
      readMore: 'Continua a leggere',
    },
  },
  misc: {
    iSenza: 'iSENZA',
    newsletterTitle: 'ISCRIVITI ALLA NEWSLETTER BIONIKE!',
    outOfStock: 'Attualmente non disponibile',
    pageBuilder: {
      multiColumnB: {
        readMore: 'Leggi di più',
      },
    },
    storeName: 'BioNike',
    variant: {
      color: 'Colore',
      size: 'Formato',
    },
  },
  product: {
    freeShippingFrom: 'Spedizioni gratuite a partire da {{freeShippingFromAmount}}',
    relatedProduct: {
      title: 'Provalo con',
    }, 
  },
  welcome: 'Benvenuto su store-bionike!',
};
