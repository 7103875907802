import './CopyB.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';
import { ProductTile } from '../ProductTile';

export interface CopyBContentType {
  background?: b2x.contentTypes.ContentSectionSwitch<{
    asset?: b2x.AssetAsBackgroundV2Props;
    color?: string;
  }>;
  content?: string;
  ordering?: string;
  productID?: string;
  subtitle?: string;
  title?: string;
}

interface CopyBProps extends CopyBContentType {
  mainComponent?: boolean;
}

export const CopyB = ({ background, content, mainComponent, ordering, productID, subtitle, title }: CopyBProps) => {
  const _background = b2x.getItemFromSwitch(background);
  return (
    <b2x.Div
      className={classnames('copy-b', { 'with-background': _background })}
      paddingY={_background ? undefined : { lg: 5, xs: 3 }}
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      {_background && (
        <b2x.Div
          background={_background.color}
          className="background"
          style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
        >
          {_background.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {..._background.asset} />}
        </b2x.Div>
      )}
      <Container>
        <b2x.Div style={{ overflow: 'hidden', position: 'relative' }}>
          <b2x.Div
            paddingX={_background ? { lg: 0, xs: 3 } : 0}
            paddingY={_background ? { lg: 5, xs: 3 } : 0}
            style={{ position: 'relative' }}
          >
            <b2x.Row className={ordering?.replace(' ', '-')} gap={5} justifyContent={'center'}>
              {(title || subtitle) && (
                <b2x.Col size={12}>
                  <b2x.ConditionalWrapper
                    condition={title !== undefined}
                    wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
                  >
                    {b2x.formatHtml(title)}
                  </b2x.ConditionalWrapper>
                  {subtitle && <b2x.Div>{b2x.formatHtml(subtitle)}</b2x.Div>}
                </b2x.Col>
              )}
              <b2x.Col className="copy-column" size={{ sm: '', xs: 12 }}>
                {content && <b2x.Div className="content">{b2x.formatHtml(content)}</b2x.Div>}
              </b2x.Col>
              {productID && (
                <b2x.Col className="product-column" size={{ lg: 4, md: 5, sm: 5, xl: 3, xs: 12 }}>
                  <b2x.ProductById id={productID}>
                    {(product) =>
                      product !== undefined && (
                        <b2x.EqualHeight>
                          <b2x.Listing name={'copy-b'} products={[product]}>
                            <ProductTile product={product} />
                          </b2x.Listing>
                        </b2x.EqualHeight>
                      )
                    }
                  </b2x.ProductById>
                </b2x.Col>
              )}
            </b2x.Row>
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
