import './AssetA.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';

export interface AssetAContentType {
  asset?: b2x.contentTypes.AssetContentSectionV2;
  container?: string;
}

interface AssetAProps extends AssetAContentType {}

export const AssetA = ({ asset, container }: AssetAProps) => {
  return (
    <b2x.Div className="asset-a" paddingY={{ lg: 5, xs: 3 }} textAlign={'center'}>
      {container === 'none' ? (
        <b2x.AssetV2 {...asset} fluid />
      ) : (
        <Container fluid={container === 'fluid' ? 'always' : 'xxl'}>
          <b2x.Row>
            <b2x.Col size={12}>
              <b2x.Div>
                <b2x.AssetV2 {...asset} fluid />
              </b2x.Div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      )}
    </b2x.Div>
  );
};
