/* eslint-disable jsx-a11y/media-has-caption */
import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import {
  MiscellaneousContentType,
  ProductContentBuilderContentType,
  ProductIconsContentType,
  ProductPromoCodeContentType,
} from '../contentTypes';
import { CustomRadio } from '../CustomRadio';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize, isIconName } from '../Icon';
import { AssetA } from '../pagebuilder/AssetA';
import { CopyA } from '../pagebuilder/CopyA';
import { DoubleColumnsA } from '../pagebuilder/DoubleColumnsA';
import { SingleColumnA } from '../pagebuilder/SingleColumnA';
import { ProductReviews } from '../ProductReviews';
import { ProductsSlider } from '../slider/ProductsSlider';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const { headerHeight, isFooterCopyrightVisible, isTopBarRowVisible } = useAppContext();
  const [variantsDivContainer, scrollToVariantsDivContainer] = b2x.useScrollTo(-180);
  const { session } = b2x.useAppContext();
  const [reviewsRef, scrollToReviews] = b2x.useScrollTo(-(headerHeight ?? 0));

  const currentBreakpoint = b2x.useBreakpoint();

  const productLine = product?.attributes?.find((attribute) => attribute.typeCode === 'BIONK_LINEA')?.value;
  const bgProductLine = product?.attributes?.find((attribute) => attribute.typeCode === 'BIONK_LINEA_COLORE')?.value;
  const colorProductLine = product?.attributes?.find(
    (attribute) => attribute.typeCode === 'BIONK_LINEA_COLORE_TESTO'
  )?.value;

  const productContentCode = product?.attributes?.find(
    (attribute) => attribute.typeCode === 'BIONK_PRODUCT_CONTENT_CODE'
  )?.value;

  return (
    <Page className="product-page" noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => {
            // const selectedSkuValue = productContext.selectedSku?.attributes?.find(
            //   (attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE'
            // )?.value;
            // const showSelectedSkuValue = productContext.selectedSku?.attributes?.some(
            //   (a) => a.typeCode === 'BIONK_ICONA_COLORE'
            // );

            const tags = productContext.selectedProductVariant.attributes
              ?.find((attribute) => attribute.typeCode === 'BIONK_TAGS')
              ?.value?.split('|');

            const selectedSkuColor = productContext.selectedSku?.attributes?.find(
              (attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE'
            )?.value;

            const variantLabel = selectedSkuColor
              ? `${t('misc.variant.color')}: ${selectedSkuColor}`
              : t('misc.variant.size');

            // const attributeEfficacia = productContext.selectedProductVariant.attributes?.find(
            //   (attribute) => attribute.typeCode === 'BIONK_EFFICACIA'
            // );

            const attributeInfograficaDesk = productContext.selectedProductVariant.attributes?.find(
              (attribute) => attribute.typeCode === 'BIONK_INFOGRAFICA_DESK'
            );

            const attributeInfograficaMob = productContext.selectedProductVariant.attributes?.find(
              (attribute) => attribute.typeCode === 'BIONK_INFOGRAFICA_MOBILE'
            );

            const attributeVideoProdotto = productContext.selectedProductVariant.attributes?.find(
              (attribute) => attribute.typeCode === 'BIONK_VIDEO_PRODOTTO'
            );

            // const attributeVideoCarosello = productContext.selectedProductVariant.attributes?.find(
            //   (attribute) => attribute.typeCode === 'BIONK_VIDEO_CAROSELLO'
            // );

            return (
              <React.Fragment>
                <b2x.structuredData.Product product={product} selectedSku={productContext.selectedSku} />
                <section className="product-first-section py-lg-5 mb-3 mb-lg-0">
                  <b2x.Container>
                    {b2x.untilBreakpoint('md', currentBreakpoint) && (
                      <b2x.Col size={12}>
                        <div className="px-3 pb-2 pt-3">
                          <b2x.Breadcrumb hideLastItem />
                        </div>
                      </b2x.Col>
                    )}
                    <b2x.Row>
                      <b2x.Col size={{ lg: 6, xs: 12 }}>
                        <div className="position-relative">
                          <div className="btn-wishlist-container position-absolute end-0 p-3" style={{ zIndex: 100 }}>
                            <b2x.WishlistButtonHelper product={product} sku={productContext.selectedSku}>
                              {({ handleWishlistButtonClick, inWishlist }) => (
                                <Button
                                  className="text-light-blue"
                                  iconEnd={{
                                    name: inWishlist ? 'wishlist-full' : 'wishlist',
                                    size: 30,
                                  }}
                                  onClick={handleWishlistButtonClick}
                                  variant="blank"
                                />
                              )}
                            </b2x.WishlistButtonHelper>
                          </div>
                          <ProductGallery
                            selectedProductVariant={productContext.selectedProductVariant}
                            selectedSku={productContext.selectedSku}
                          />
                        </div>
                      </b2x.Col>
                      <b2x.Col size={{ lg: 6, xs: 12 }}>
                        <div className="px-3 p-lg-0">
                          {!b2x.untilBreakpoint('md', currentBreakpoint) && (
                            <b2x.Div className="border-bottom" marginBottom={3} paddingBottom={1}>
                              <b2x.Breadcrumb hideLastItem />
                            </b2x.Div>
                          )}
                          {!!productLine && <div className="product-short-description small mb-2">{productLine}</div>}
                          {productContext.selectedProductVariant.name && (
                            <h1 className="product-title h2 fw-bold">
                              {b2x.formatHtml(productContext.selectedProductVariant.name)}
                            </h1>
                          )}
                          {productContext.selectedSku?.name && (
                            <b2x.Div className="small fw-bold" marginBottom={2}>
                              {productContext.selectedSku.name}
                            </b2x.Div>
                          )}
                          <b2x.SummaryReviewsPreview
                            className="mb-3"
                            productId={productContext.selectedProductVariant.id}
                            productSummaryReviews={productContext.selectedProductVariant.summaryReviews}
                            scrollTo={scrollToReviews}
                          />
                          {tags && tags.length > 0 && (
                            <b2x.Div display="flex" flexWrap="wrap" gap={2} marginBottom={3}>
                              {tags.map((tag) => (
                                <b2x.Badge
                                  backgroundColor={bgProductLine && b2x.hexToRgba(bgProductLine, 0.7)}
                                  className="text-uppercase fw-normal"
                                  key={tag}
                                  textColor={colorProductLine ? colorProductLine : '#000'}
                                >
                                  {tag}
                                </b2x.Badge>
                              ))}
                            </b2x.Div>
                          )}
                          <b2x.AddToCartFormHelper className="mb-3" product={product} scope="product">
                            {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                              <React.Fragment>
                                <div className="product-variants-container" ref={variantsDivContainer}>
                                  {fieldsHelper.productVariants.formFields.length > 0 && (
                                    <b2x.FormGroup
                                      {...fieldsHelper.productVariants.formGroup}
                                      className="my-2 product-variants-container-form-group"
                                      label={fieldsHelper.productVariants.formGroup.label}
                                      labelClassName="mb-1 fw-bold"
                                      noMarginBottom
                                    >
                                      {fieldsHelper.productVariants.formFields.map(({ productVariant, radio }) => {
                                        const label =
                                          productVariant.attributes
                                            ?.find((attribute) => attribute.typeCode === 'BIONK_FORMATO')
                                            ?.value?.toLowerCase() ?? '';

                                        return <CustomRadio key={radio.id} label={label} radio={radio} type={'text'} />;
                                      })}
                                    </b2x.FormGroup>
                                  )}
                                  {fieldsHelper.skus.formFields.length > 1 && (
                                    <div className="product-skus">
                                      <b2x.FormGroup
                                        {...fieldsHelper.skus.formGroup}
                                        className="my-2 product-variants-container-form-group"
                                        label={variantLabel}
                                        labelClassName="mb-1 fw-bold"
                                        noMarginBottom
                                      >
                                        <b2x.SwiperContext>
                                          {({ navigationNextElRef, navigationPrevElRef }) => {
                                            return (
                                              <b2x.Div className="slider-container">
                                                <Button
                                                  className="prev"
                                                  iconStart={{ name: 'arrow-left-light' }}
                                                  innerRef={navigationPrevElRef}
                                                  justifyContent="start"
                                                  variant="blank"
                                                />
                                                <b2x.Swiper
                                                  navigation={{ custom: true }}
                                                  slides={fieldsHelper.skus.formFields.map(({ radio, sku }) => {
                                                    const label =
                                                      sku.attributes
                                                        ?.find(
                                                          (attribute) => attribute.typeCode === 'BIONK_NOME_VARIANTE'
                                                        )
                                                        ?.value?.toLocaleLowerCase() ??
                                                      sku.name?.toLocaleLowerCase() ??
                                                      '';

                                                    const imageUrl = sku.attributes?.find(
                                                      (attribute) => attribute.typeCode === 'BIONK_ICONA_COLORE'
                                                    )?.value;

                                                    const type = imageUrl ? 'icon' : 'text';
                                                    return (
                                                      <CustomRadio
                                                        image={
                                                          imageUrl
                                                            ? { alt: label, title: label, url: imageUrl }
                                                            : undefined
                                                        }
                                                        key={radio.id}
                                                        label={label}
                                                        radio={radio}
                                                        type={type}
                                                      />
                                                    );
                                                  })}
                                                  slidesPerView={'auto'}
                                                  spaceBetween={8}
                                                />
                                                <Button
                                                  className="next"
                                                  iconStart={{ name: 'arrow-right-light' }}
                                                  innerRef={navigationNextElRef}
                                                  justifyContent="end"
                                                  variant="blank"
                                                />
                                              </b2x.Div>
                                            );
                                          }}
                                        </b2x.SwiperContext>
                                      </b2x.FormGroup>
                                    </div>
                                  )}
                                </div>
                                <ProductPrice priceHelper={priceHelper} />
                                <div className="hstack gap-3 mb-3 mb-md-4">
                                  {selectedSku?.state === 'AVAILABLE' && (
                                    <b2x.FormGroup
                                      className="quantity-container"
                                      {...fieldsHelper.quantity.formGroup}
                                      label={undefined}
                                      noMarginBottom
                                    >
                                      <div className="d-flex border border-primary overflow-hidden h-100">
                                        <b2x.Button
                                          className="px-2"
                                          variant="outline-link"
                                          {...fieldsHelper.buttons.quantity.decrease}
                                          iconStart={{ name: 'minus', size: 12 }}
                                          label={undefined}
                                        />
                                        <b2x.NumberInput
                                          className="border-0 text-center fw-bold bg-transparent px-2 py-2"
                                          style={{ width: 45 }}
                                          {...fieldsHelper.quantity.numberInput}
                                          placeholder={undefined}
                                          readOnly
                                        />
                                        <b2x.Button
                                          className="px-2"
                                          variant="outline-link"
                                          {...fieldsHelper.buttons.quantity.increase}
                                          iconStart={{ name: 'plus', size: 12 }}
                                          label={undefined}
                                        />
                                        {/* <div className="btn-container" style={{ flex: '0 0 auto' }}></div>
                                      <div className="px-2" style={{ flex: '0 0 auto', width: 45 }}></div>
                                      <div className="btn-container" style={{ flex: '0 0 auto' }}></div> */}
                                      </div>
                                    </b2x.FormGroup>
                                  )}
                                  <div className="add-to-cart-button-container">
                                    <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                      fieldsHelper={fieldsHelper}
                                      selectedSku={selectedSku}
                                      submitButton={
                                        b2x.untilBreakpoint('xs', currentBreakpoint)
                                          ? {
                                              iconEnd: { name: 'shopping-cart', size: 20 },
                                              label: undefined,
                                              variant:
                                                selectedSku?.state === 'AVAILABLE' ? 'primary' : 'outline-primary',
                                            }
                                          : {
                                              label:
                                                selectedSku?.state === 'AVAILABLE'
                                                  ? t('form.addToCartForm.buttons.submit.label')
                                                  : t('misc.productNotAvailable'),
                                              variant:
                                                selectedSku?.state === 'AVAILABLE' ? 'primary' : 'outline-primary',
                                            }
                                      }
                                    />
                                  </div>
                                </div>
                                <b2x.Portal>
                                  <b2x.ProductStickyFooter
                                    fieldsHelper={fieldsHelper}
                                    formik={formik}
                                    isVisible={!isTopBarRowVisible && !isFooterCopyrightVisible}
                                    priceHelper={priceHelper}
                                    product={product}
                                    scrollToElement={scrollToVariantsDivContainer}
                                    selectedProductImage={selectedProductVariant.image}
                                    selectedSku={selectedSku}
                                  />
                                </b2x.Portal>
                              </React.Fragment>
                            )}
                          </b2x.AddToCartFormHelper>
                          <ProductPromoCode attributes={product.attributes} />
                          <AttributesSection
                            product={productContext.selectedProductVariant}
                            sku={productContext.selectedSku}
                          />
                          <Isenza product={productContext.selectedProductVariant} sku={productContext.selectedSku} />
                          {session?.cart?.shippingProfile?.freeThreshold && (
                            <div className="alert bg-nude-300 small fw-bold text-primary border-0 hstack gap-2 mb-0">
                              <Icon name="delivery" size={24} />
                              {b2x.formatHtml(
                                t('product.freeShippingFrom', {
                                  freeShippingFromAmount: b2x.formatCurrency(
                                    session.cart.shippingProfile.freeThreshold
                                  ),
                                })
                              )}
                            </div>
                          )}
                        </div>
                      </b2x.Col>
                    </b2x.Row>
                  </b2x.Container>
                </section>
                {/* {attributeEfficacia?.value && (
                  <b2x.Div className="efficacia" marginBottom={{ lg: 5, xs: 3 }}>
                    <Container>
                      <b2x.P className="h3 fw-bold mb-3">{attributeEfficacia.title}</b2x.P>
                      {b2x.formatHtml(attributeEfficacia.value)}
                    </Container>
                  </b2x.Div>
                )} */}
                {attributeVideoProdotto?.value && (
                  <b2x.Div className="video-prodotto" marginBottom={{ lg: 5, xs: 3 }}>
                    <Container>
                      <b2x.Video
                        variants={{
                          xs: {
                            height: 1080,
                            source: { external: { file: attributeVideoProdotto.value } },
                            width: 1920,
                          },
                        }}
                      />
                    </Container>
                  </b2x.Div>
                )}
                {attributeInfograficaDesk?.value && (
                  <b2x.Div
                    className="infografica-desktop"
                    display={{ lg: 'block', xs: 'none' }}
                    marginBottom={{ lg: 5, xs: 3 }}
                  >
                    <Container>
                      <b2x.Image fluid src={{ xs: attributeInfograficaDesk.value }} />
                    </Container>
                  </b2x.Div>
                )}
                {attributeInfograficaMob?.value && (
                  <b2x.Div
                    className="infografica-mobile"
                    display={{ lg: 'none', xs: 'block' }}
                    marginBottom={{ lg: 5, xs: 3 }}
                  >
                    <Container>
                      <b2x.Image fluid src={{ xs: attributeInfograficaMob.value }} />
                    </Container>
                  </b2x.Div>
                )}
                {productContentCode && <ProductContent code={productContentCode} />}
                <ProductReviews innerRef={reviewsRef} product={product} />
                <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                  {({ fetching, relatedProducts }) =>
                    fetching ? (
                      <b2x.Loading />
                    ) : (
                      relatedProducts &&
                      relatedProducts.length > 0 && (
                        <section className="product-third-section py-md-5">
                          <b2x.Container>
                            <h3
                              className={classnames(
                                { h1: !b2x.untilBreakpoint('sm', currentBreakpoint) },
                                'fw-bold text-uppercase text-center mb-md-5'
                              )}
                            >
                              {t('product.relatedProduct.title')}
                            </h3>
                            <b2x.Listing
                              name="Product page - Related products"
                              products={relatedProducts}
                              sendEvent={false}
                            >
                              <ProductsSlider products={relatedProducts} />
                            </b2x.Listing>
                          </b2x.Container>
                        </section>
                      )
                    )
                  }
                </b2x.RelatedProducts>
              </React.Fragment>
            );
          }}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductPriceProps {
  priceHelper: b2x.UsePriceResult;
}

const ProductPrice = ({ priceHelper }: ProductPriceProps) => {
  return (
    <b2x.Div
      className="product-price-container"
      display="flex"
      flexDirection="column"
      gap={2}
      marginY={{
        lg: 4,
        xs: 3,
      }}
    >
      <div>
        <b2x.PriceBlock
          alignItems="center"
          classNames={{
            discountPercentage: 'bg-primary text-white px-2',
            price: 'fw-bold',
          }}
          gap={2}
          priceHelper={priceHelper}
        />
      </div>
      {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
        <div className="extra-small">
          <b2x.BestPrice priceHelper={priceHelper} />
        </div>
      )}
    </b2x.Div>
  );
};

interface ProductGalleryProps {
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductGallery = ({ selectedProductVariant, selectedSku }: ProductGalleryProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<b2x.SwiperClass>();

  const { stickersProductWithoutImage } = b2x.useStickersProduct({ product: selectedProductVariant });

  const slides = b2x.useGallery(selectedProductVariant, selectedSku).slides;

  return (
    <div className="product-gallery d-flex mb-3 gap-3">
      <div className="gallery-thumbnails d-none d-lg-block">
        <b2x.SwiperContext>
          <b2x.Swiper
            direction="vertical"
            onSwiper={setThumbsSwiper}
            slides={slides.map(({ src, thumbnail, type, uId }) => (
              <div className="slide-container" key={uId}>
                {type === 'image' ? (
                  <b2x.ImageSwitcher aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={thumbnail} />
                ) : (
                  <div className="position-relative">
                    <b2x.Video
                      options={{
                        autoplay: false,
                        controls: false,
                        loop: false,
                        muted: false,
                        pip: false,
                        playsinline: false,
                      }}
                      variants={{ xs: { height: 1080, source: { external: { file: src } }, width: 1920 } }}
                    />
                    <b2x.Div
                      alignItems={'center'}
                      className="position-absolute w-100 h-100 top-0 start-0 bg-dark bg-opacity-25 text-light"
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <Icon name="play-rounded" size={20} />
                    </b2x.Div>
                  </div>
                )}
              </div>
            ))}
            slidesPerView={'auto'}
            spaceBetween={16}
            watchSlidesProgress
          />
        </b2x.SwiperContext>
      </div>
      <div className="gallery-slides position-relative">
        {stickersProductWithoutImage && stickersProductWithoutImage.length > 0 && (
          <b2x.Div className="product-stickers pt-3 px-3 d-flex gap-3">
            {stickersProductWithoutImage.map((sticker) => (
              <b2x.Div
                className={classnames(
                  'sticker',
                  sticker.code,
                  'px-2 text-uppercase fw-bold',
                  { 'bg-transparent': !sticker.backgroundColor },
                  { 'border border-black': !sticker.borderColor },
                  { 'text-black': !sticker.textColor }
                )}
                key={sticker.code}
                style={{
                  backgroundColor: sticker.backgroundColor,
                  border: sticker.borderColor ? `1px solid ${sticker.borderColor}` : undefined,
                  color: sticker.textColor,
                }}
              >
                {sticker.label}
              </b2x.Div>
            ))}
          </b2x.Div>
        )}
        <b2x.Lightbox>
          <b2x.SwiperContext>
            {(swiper) => (
              <b2x.Swiper
                pagination
                slides={slides.map(({ src, type, uId }, index) => (
                  <div className="slide-container" key={uId}>
                    {type === 'image' ? (
                      <b2x.ImageSwitcher aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={src} />
                    ) : (
                      swiper.swiper?.activeIndex === index && (
                        <b2x.Video
                          options={{
                            autoplay: swiper.swiper?.activeIndex === index,
                            controls: true,
                            loop: true,
                            muted: true,
                            pip: false,
                            playsinline: true,
                          }}
                          variants={{ xs: { height: 1080, source: { external: { file: src } }, width: 1920 } }}
                        />
                      )
                    )}
                  </div>
                ))}
                thumbs={{
                  slideThumbActiveClass: 'border',
                  swiper: thumbsSwiper,
                }}
              />
            )}
          </b2x.SwiperContext>
        </b2x.Lightbox>
      </div>
    </div>
  );
};

interface AttributesSectionProps {
  product: b2x.ProductApiDto;
  sku?: b2x.SkuApiDto;
}

const AttributesSection = ({ product, sku }: AttributesSectionProps) => {
  const ATTRS_TO_SHOW = ['BIONK_ATTIVI', 'BIONK_INGREDIENTI', 'BIONK_APPLICAZIONE', 'BIONK_VALORI_MEDI'];
  // Importante
  // Per ripristinare l'accordion della sostenibilità modificare il typecode dell'attributo con BIONK_SOSTENIBILITA
  const attrSostenibilita = product.attributes?.find((attribute) =>
    attribute.typeCode.includes('BIONK_SOSTENIBILITA_DISABLED')
  );

  const attrIngredienti = sku?.attributes?.find((attribute) => attribute.typeCode === 'BIONK_INGREDIENTI');

  return (
    <section className="py-3 mb-3 position-relative">
      <Accordion id="product-accordion">
        {product.description && (
          <b2x.AccordionItem id="product-description" show title={t('product.description')}>
            <div className="mb-3">{b2x.formatHtml(product.description)}</div>
            {sku?.code && (
              <div className="text-primary text-gray-400 small">
                {t('product.code')}: {sku.code.replace('B2X_', '')}
              </div>
            )}
          </b2x.AccordionItem>
        )}

        {product.attributes?.map(
          (attribute) =>
            ATTRS_TO_SHOW.includes(attribute.typeCode) &&
            attribute.value && <AttributeAccordionItem attribute={attribute} key={attribute.typeCode} />
        )}

        {attrIngredienti && <AttributeAccordionItem attribute={attrIngredienti} />}

        {attrSostenibilita && <AttributeAccordionItem attribute={attrSostenibilita} icon="sustainability" />}
      </Accordion>
    </section>
  );
};

const AttributeAccordionItem = ({ attribute, icon }: { attribute: b2x.AttributeApiDto; icon?: IconName }) => {
  return (
    <b2x.AccordionItem
      accordionBodyClassName={icon ? 'bg-mint' : ''}
      accordionButtonClassName={icon ? 'bg-mint' : ''}
      id={attribute.typeCode}
      title={
        <b2x.Div className="hstack w-100" justifyContent="spaceBetween" paddingEnd={3}>
          <b2x.Span alignItems="center" display="flex" gap={2}>
            {icon && <Icon name={icon} size={30} />}
            {attribute.title}
          </b2x.Span>
        </b2x.Div>
      }
    >
      {b2x.formatHtml(attribute.value)}
    </b2x.AccordionItem>
  );
};

interface ProductPromoCodePros {
  attributes?: Array<b2x.AttributeApiDto>;
}

const ProductPromoCode = ({ attributes }: ProductPromoCodePros) => {
  // Recupero il valore dall'attributo impostato.
  const productPromoCodeContent = attributes?.find(
    (attribute) => attribute.typeCode === 'BIONK_PROMO_CONTENT_CODE'
  )?.value;

  const miscContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  // Se presente un valore, recupero il contenuto del banner.
  const content = productPromoCodeContent && b2x.useContent<ProductPromoCodeContentType>(productPromoCodeContent);
  const { getPagePath } = b2x.useAppStaticContext();
  const { showModal } = b2x.useModals();

  const codeRef = React.useRef<HTMLSpanElement>(null);
  const handleCopyCodeButtonClick = React.useCallback(() => {
    const couponCode = codeRef.current?.innerText ?? '';
    navigator.clipboard.writeText(couponCode).then((response) => {
      showModal({
        autoClose: 2,
        centered: true,
        children: miscContent?.body.productPage?.couponCodeBanner?.contentModal,
        className: 'modal-copy-coupon',
        closable: false,
        title: miscContent?.body.productPage?.couponCodeBanner?.titleModal || '',
      });
    });
  }, [
    miscContent?.body.productPage?.couponCodeBanner?.contentModal,
    miscContent?.body.productPage?.couponCodeBanner?.titleModal,
    showModal,
  ]);

  return (
    <>
      {typeof content === 'object' && content.body.backgroundColor && (
        <div
          className="product-promo-code mb-4 px-3 py-2"
          style={{
            background: content.body.backgroundColor,
          }}
        >
          <b2x.ConditionalWrapper
            condition={content.body.ctaBanner !== undefined}
            wrapper={
              <b2x.router.Link
                className="text-decoration-none"
                style={{ color: content.body.textColor }}
                to={
                  content.body.ctaBanner?.to?.href
                    ? content.body.ctaBanner.to.href
                    : content.body.ctaBanner?.to?.code && getPagePath(content.body.ctaBanner.to.code)
                }
              />
            }
          >
            <b2x.Row className="align-items-center" gap={{ lg: 3, xs: 1 }}>
              <b2x.Col>
                <b2x.Div alignItems="center" display="flex" gap={3}>
                  {content.body.lottieIcon ? (
                    <div style={{ width: 30 }}>
                      <b2x.LottieElement src={b2x.getBcomFilePath(content.body.lottieIcon)} />
                    </div>
                  ) : (
                    content.body.icon && (
                      <div style={{ width: 30 }}>
                        <Icon name={content.body.icon as IconName} size={30} />
                      </div>
                    )
                  )}
                  <div className="d-flex flex-column">
                    <div className="small mb-2 fw-bold">{b2x.formatHtml(content.body.title)}</div>
                    <div className="small">{b2x.formatHtml(content.body.description)}</div>
                    {content.body.couponCode && (
                      <div className="extra-small mb-1 mb-xl-0">
                        <span className="text-uppercase extra-small"> {t('misc.code')}: </span>
                        <span className="fw-bold extra-small " ref={codeRef}>
                          {b2x.formatHtml(content.body.couponCode)}
                        </span>
                      </div>
                    )}
                    <div className="extra-small mt-2">{b2x.formatHtml(content.body.footer)}</div>
                    {content.body.ctaBanner?.label && (
                      <b2x.Col size={{ xs: 12 }}>
                        <b2x.Div marginY={3}>
                          <b2x.CtaFromContent {...content.body.ctaBanner} />
                        </b2x.Div>
                      </b2x.Col>
                    )}
                  </div>
                </b2x.Div>
              </b2x.Col>
              <b2x.Col className="button-col" size={{ sm: 'auto', xl: 'auto', xs: 12 }}>
                {content.body.couponCode && (
                  <div className="pb-3 pb-sm-0">
                    <b2x.Button
                      label={content.body.cta?.label}
                      onClick={handleCopyCodeButtonClick}
                      variant={content.body.cta?.variant}
                    />
                  </div>
                )}
              </b2x.Col>
            </b2x.Row>
          </b2x.ConditionalWrapper>
        </div>
      )}
    </>
  );
};

interface ProductContentProps {
  code: string;
}

const ProductContent = ({ code }: ProductContentProps) => {
  const content = b2x.useContent<ProductContentBuilderContentType>(code);
  return (
    <React.Fragment>
      {content?.body.sections?.map((section) => {
        let component = null;
        const sectionComponent = b2x.getItemFromSwitch(section.component);

        if (sectionComponent?.assetA) {
          component = <AssetA {...sectionComponent.assetA} />;
        }

        if (sectionComponent?.copyA) {
          component = <CopyA {...sectionComponent.copyA} />;
        }

        if (sectionComponent?.singleColumnA) {
          component = <SingleColumnA {...sectionComponent.singleColumnA} />;
        }

        if (sectionComponent?.doubleColumnsA) {
          component = <DoubleColumnsA {...sectionComponent.doubleColumnsA} />;
        }

        return (
          <React.Fragment key={section.contentSectionId}>
            {section.options?.showSection && (
              <b2x.Section
                className={classnames('component-section')}
                marginBottom={{
                  lg: section.options.marginBottom?.lg as b2x.MarginSize,
                  md: section.options.marginBottom?.md as b2x.MarginSize,
                  sm: section.options.marginBottom?.sm as b2x.MarginSize,
                  xl: section.options.marginBottom?.xl as b2x.MarginSize,
                  xs: section.options.marginBottom?.xs as b2x.MarginSize,
                  xxl: section.options.marginBottom?.xxl as b2x.MarginSize,
                }}
              >
                {component}
              </b2x.Section>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

interface IsenzaProps {
  product: b2x.ProductApiDto;
  sku?: b2x.SkuApiDto;
}

const Isenza = ({ product, sku }: IsenzaProps) => {
  const productIconContent = b2x.useContent<ProductIconsContentType>('PRUDUCT_ICONS_CONTENT')?.body.icons;

  const skuISenzaWithoutIcon = sku?.attributes
    ?.find((attribute) => attribute.typeCode === 'BIONK_SENZA')
    ?.value?.split('|');
  const productISenzaWithoutIcon = product.attributes
    ?.find((attribute) => attribute.typeCode === 'BIONK_SENZA')
    ?.value?.split('|');
  const iSenzaWithoutIcon =
    skuISenzaWithoutIcon && skuISenzaWithoutIcon.length > 0 ? skuISenzaWithoutIcon : productISenzaWithoutIcon;

  const skuISenzaWithIcon = sku?.attributes?.filter((attribute) => attribute.typeCode.startsWith('BIONK_ICON_'));
  const productISenzaWithIcon = product.attributes?.filter((attribute) => attribute.typeCode.startsWith('BIONK_ICON_'));
  let iSenzaWithIcon = skuISenzaWithIcon && skuISenzaWithIcon.length > 0 ? skuISenzaWithIcon : productISenzaWithIcon;

  // Rimuovo dai prodotti quelli con l'icona senza glutine
  iSenzaWithIcon = iSenzaWithIcon?.filter((item) => item.typeCode !== 'BIONK_ICON_GLUTINE');

  const arrayFiltered = productIconContent?.filter((content) =>
    iSenzaWithIcon?.some((attribute) => content.attributeCode === attribute.typeCode)
  );

  return (
    <React.Fragment>
      {((arrayFiltered && arrayFiltered.length > 0) || (iSenzaWithoutIcon && iSenzaWithoutIcon.length > 0)) && (
        <b2x.Div className="isenza extra-small mb-4">
          <b2x.P className="bg-nude px-2 py-1" display={'inline-block'}>
            {t('misc.iSenza')}
          </b2x.P>
          {arrayFiltered && arrayFiltered.length > 0 && (
            <b2x.Div className="isenza-with-icon text-uppercase text-center mb-3">
              <b2x.Row gap={{ lg: 0, xs: 3 }}>
                {arrayFiltered.map(({ contentSectionId, icon, label }) => {
                  const iconType = b2x.getItemFromSwitch(icon);

                  return (
                    <b2x.Col key={contentSectionId} size={{ sm: 'auto', xs: 6 }}>
                      <b2x.Div className="isenza-icon-container" paddingX={4}>
                        <b2x.Div className="icon-container" marginBottom={1}>
                          {iconType?.fromTemplate
                            ? isIconName(iconType.fromTemplate) && <Icon name={iconType.fromTemplate} size={44} />
                            : iconType?.image && <b2x.ImageFromContentV2 height={44} width={44} {...iconType.image} />}
                        </b2x.Div>
                        <b2x.P className="lh-1" margin={0}>
                          {label}
                        </b2x.P>
                      </b2x.Div>
                    </b2x.Col>
                  );
                })}
              </b2x.Row>
            </b2x.Div>
          )}
          {iSenzaWithoutIcon && iSenzaWithoutIcon.length > 0 && (
            <b2x.Div
              className={classnames(
                'isenza-without-icon text-uppercase px-4',
                { 'pt-3 border-top border-gray-300': arrayFiltered && arrayFiltered.length > 0 },
                { 'pt-2': arrayFiltered && arrayFiltered.length === 0 }
              )}
            >
              <b2x.Row gap={0}>
                {iSenzaWithoutIcon.map((senza, index) => (
                  <React.Fragment key={senza}>
                    {index > 0 && (
                      <b2x.Col size={'auto'}>
                        <b2x.Div paddingX={2}>-</b2x.Div>
                      </b2x.Col>
                    )}
                    <b2x.Col size={'auto'}>
                      <b2x.Div>{senza}</b2x.Div>
                    </b2x.Col>
                  </React.Fragment>
                ))}
              </b2x.Row>
            </b2x.Div>
          )}
        </b2x.Div>
      )}
    </React.Fragment>
  );
};
