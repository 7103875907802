import './IconsD.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Icon, isIconName } from '../Icon';

type IconType = b2x.contentTypes.ContentSectionSwitch<{
  image?: b2x.contentTypes.ImageContentSectionV2;
  svg?: string;
}>;

type IconColumnType = {
  content?: string;
  title?: string;
  type?: IconType;
};

export interface IconsDContentType {
  background?: string;
  icons?: b2x.contentTypes.ContentSectionList<IconColumnType>;
}

interface IconsDProps extends IconsDContentType {
  mainComponent?: boolean;
}

const RenderIcon = ({ type }: { type: IconType }) => {
  const iconType = b2x.getItemFromSwitch(type);

  return (
    <>
      {iconType?.svg && isIconName(iconType.svg) && iconType.svg !== 'senza-glutine' ? (
        <Icon name={iconType.svg} size={50} />
      ) : (
        iconType?.image && <b2x.ImageFromContentV2 {...iconType.image} fluid style={{ width: 50 }} />
      )}
    </>
  );
};

const IconColumn = ({ content, title, type }: IconColumnType) => {
  return (
    <b2x.Div className="icon-column" display="flex" flexDirection="column" gap={3} textAlign="start">
      {type?.length && (
        <b2x.Div className="icon-container" marginX="auto">
          <RenderIcon type={type} />
        </b2x.Div>
      )}
      {title && (
        <b2x.P className="title" marginBottom={0}>
          {title}
        </b2x.P>
      )}
      {content && (
        <b2x.P className="subTitle" marginBottom={0}>
          {content}
        </b2x.P>
      )}
    </b2x.Div>
  );
};

export const IconsD = ({ background, icons, mainComponent }: IconsDProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  return (
    <b2x.Div background={background} className="icons-d">
      <Container>
        <b2x.Div padding={{ lg: 5, sm: 4, xs: 3 }}>
          <b2x.Div marginX={{ lg: 5, xs: 0 }}>
            {isMobile ? (
              <b2x.EqualHeight>
                <b2x.SwiperContext>
                  {() => (
                    <b2x.Div>
                      <b2x.Swiper
                        navigation={{ custom: true }}
                        pagination
                        slides={icons?.map((icon) => {
                          return (
                            <b2x.Div key={icon.contentSectionId}>
                              <IconColumn {...icon} />
                            </b2x.Div>
                          );
                        })}
                        slidesPerView={1}
                        spaceBetween={20}
                        watchSlidesProgress
                      />
                    </b2x.Div>
                  )}
                </b2x.SwiperContext>
              </b2x.EqualHeight>
            ) : (
              <b2x.Row
                gap={{
                  lg: 5,
                  xs: 3,
                }}
              >
                {icons?.map((icon) => (
                  <b2x.Col
                    key={icon.contentSectionId}
                    size={{
                      lg: 6,
                      xs: 12,
                    }}
                  >
                    <IconColumn {...icon} />
                  </b2x.Col>
                ))}
              </b2x.Row>
            )}
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
