import { GetProductOptions } from '@b2x/storefront-api-js-client/src/products';

import { ApiRequestConfig } from './api/useApiRequest';
import { useProductById, UseProductByIdResult } from './useProductById';

export interface ProductByIdProps<ContentType> {
  children?(props: UseProductByIdResult<ContentType>): React.ReactNode;
  config?: ApiRequestConfig;
  id: string;
  options?: GetProductOptions;
}

export const ProductById = <ContentType,>({ children, config, id, options }: ProductByIdProps<ContentType>) => {
  const product = useProductById<ContentType>(id, options, config);

  return <>{children && children(product)}</>;
};
