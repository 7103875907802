import { AssetAsBackgroundContentSectionV2 } from './contentTypes';
import { ImageAsBackgroundFromContentV2 } from './ImageAsBackgroundFromContentV2';
import { getItemFromSwitch } from './util';
import { VideoAsBackgroundFromContentV2 } from './VideoAsBackgroundFromContentV2';

export interface AssetAsBackgroundV2Props extends AssetAsBackgroundContentSectionV2 {
  backgroundSize?: 'cover' | 'contain' | 'initial';
}

export const AssetAsBackgroundV2 = ({ backgroundSize, type }: AssetAsBackgroundV2Props) => {
  const asset = getItemFromSwitch(type);

  return (
    <>
      {asset?.image ? (
        <ImageAsBackgroundFromContentV2 {...asset.image} backgroundSize={backgroundSize} />
      ) : (
        asset?.video && <VideoAsBackgroundFromContentV2 {...asset.video} />
      )}
    </>
  );
};
