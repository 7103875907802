import { b2x } from '@b2x/react/src';

import srcMobile from './images/logo-mobile.svg';
import srcRegular from './images/logo-regular.svg';
import srcSticky from './images/logo-sticky.svg';

export interface LogoProps {
  recalculateHeaderHeight(): void;
  type: 'regular' | 'mobile' | 'sticky';
}

export const Logo = ({ recalculateHeaderHeight, type }: LogoProps) => {
  return (
    <div className={`logo logo-${type}`}>
      <b2x.router.Link className="d-block" to="/">
        <b2x.Image
          onLoad={recalculateHeaderHeight}
          src={type === 'regular' ? srcRegular : type === 'sticky' ? srcSticky : srcMobile}
        />
      </b2x.router.Link>
    </div>
  );
};
