import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { Icon } from './Icon';
import { Logo } from './Logo';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSearchOffcanvas } from './MobileSearchOffcanvas';

export interface MobileHeaderProps {
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSearchOffcanvas, showMobileSearchOffcanvas] = useMobileSearchOffcanvas();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      {MobileNavigationOffcanvas}
      {MobileSearchOffcanvas}
      <b2x.Sticky className={classnames(`mobile-header d-${visibleUntil}-none bg-white shadow-sm py-2`)} offset={-1}>
        {({ isSticky }) => (
          <div ref={innerRef}>
            <Container className="g-0">
              <b2x.Row className="px-2" gap={0}>
                <b2x.Col className="d-flex justify-content-start align-items-center">
                  <div className="hstack gap-1">
                    <Button
                      className="p-2"
                      iconEnd={{ name: 'menu', size: 22 }}
                      onClick={showMobileNavigationOffcanvas}
                      variant="blank"
                    />
                    <Button
                      className="p-2"
                      iconEnd={{ name: 'search', size: 22 }}
                      onClick={showMobileSearchOffcanvas}
                      variant="blank"
                    />
                  </div>
                </b2x.Col>
                <b2x.Col size={'auto'}>
                  <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="mobile" />
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end align-items-center">
                  <div className="hstack gap-1">
                    {session?.customer ? (
                      <b2x.router.Link className="p-2" to={getPagePath('SITE_ACCOUNT')}>
                        <Icon name="account-logged-in" size={22} />
                      </b2x.router.Link>
                    ) : (
                      <Button
                        className="p-2"
                        iconEnd={{ name: 'account', size: 22 }}
                        onClick={showAccountOffcanvas}
                        variant="blank"
                      />
                    )}
                    <Button className="position-relative p-2" onClick={showCartOffcanvas} variant="blank">
                      <Icon name={'shopping-cart'} size={22} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};
