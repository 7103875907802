import { b2x } from '@b2x/react/src';

import { ThankYouPageContentType } from '../contentTypes';
import { MessageBoxPage } from './MessageBoxPage';

export interface ThankYouPageOrderFailedProps {}

export const ThankYouPageOrderFailed = (props: ThankYouPageOrderFailedProps) => {
  const content = b2x.useContent<ThankYouPageContentType>('THANKYOU_PAGE_FAILED_CONTENT');
  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-end">
      <div className="py-5 px-3">
        <h1 className="h3 text-uppercase fw-medium">{b2x.formatHtml(content?.body.title)}</h1>
        <p>{b2x.formatHtml(content?.body.subTitle)}</p>
        <p className="small fw-light m-0">{b2x.formatHtml(content?.body.content)}</p>
      </div>
    </MessageBoxPage>
  );
};
