import './ProductTileStickers.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { ProductApiDto } from '../../storefront-api-js-client/src';

export interface ProductTileStickersProps {
  product: ProductApiDto;
  selectedVariant?: ProductApiDto;
  size?: 'large' | 'default';
}

export const ProducTiletStickers = ({ product, selectedVariant, size = 'default' }: ProductTileStickersProps) => {
  const { showModal } = b2x.useModals();
  //const breakpoint = b2x.useBreakpoint();
  const { stickersProductWithoutImage } = b2x.useStickersProduct({ product: selectedVariant ?? product });

  const productOffers = selectedVariant ? selectedVariant.offers : product.offers;

  return (
    <div className="product-tile-stickers ps-2 ps-md-3">
      <b2x.Row alignItems={'start'} gap={{ md: 3, xs: 2 }}>
        {(stickersProductWithoutImage || (productOffers && productOffers.length > 0)) && (
          // <b2x.Col>
          //   <b2x.Div>
          //     <b2x.Row gap={{ md: 3, xs: 1 }}>
          <>
            {stickersProductWithoutImage?.map((sticker) => (
              <b2x.Col key={sticker.code} size={'auto'}>
                <b2x.Div
                  className={classnames(
                    sticker.code,
                    'px-2 mt-2 mt-md-3 text-uppercase fw-bold',
                    { 'extra-small': size === 'default' },
                    { 'bg-transparent': !sticker.backgroundColor },
                    { 'border border-black': !sticker.borderColor },
                    { 'text-black': !sticker.textColor }
                  )}
                  paddingX={1}
                  style={{
                    backgroundColor: sticker.backgroundColor,
                    border: sticker.borderColor ? `1px solid ${sticker.borderColor}` : undefined,
                    color: sticker.textColor,
                  }}
                >
                  {sticker.label}
                </b2x.Div>
              </b2x.Col>
            ))}
            {productOffers?.map((offer) => (
              <b2x.Col key={offer.id} size={'auto'}>
                <b2x.Div display={'grid'}>
                  <b2x.Button
                    className={classnames('btn-sm px-2 py-0 fw-normal', {
                      'extra-small': size === 'default',
                    })}
                    iconEnd={{ name: 'info', size: 14 }}
                    label={offer.shortDescription}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => {
                      showModal({
                        children: offer.description,
                        size: 'default',
                        title: offer.shortDescription ? offer.shortDescription : '',
                      });
                    }}
                    style={{ pointerEvents: 'initial' }}
                  />
                </b2x.Div>
              </b2x.Col>
            ))}
          </>
          //     </b2x.Row>
          //   </b2x.Div>
          // </b2x.Col>
        )}
        {/* {stickersProductWithImage && (
          <b2x.Col size={'auto'}>
            <div className="text-end" style={{ maxWidth: b2x.untilBreakpoint(breakpoint, 'sm') ? 45 : 30 }}>
              <b2x.Row gap={{ md: 2, xs: 1 }}>
                {stickersProductWithImage.map((sticker) => (
                  <b2x.Col key={sticker.code} size={12}>
                    <b2x.ImageFromContentV1 {...sticker.image} fluid />
                  </b2x.Col>
                ))}
              </b2x.Row>
            </div>
          </b2x.Col>
        )} */}
      </b2x.Row>
    </div>
  );
};
