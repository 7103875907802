import './MagazineA.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Button } from '../Button';
import { Container } from '../Container';
import { MagazineArticlePageContentType } from '../contentTypes';
import { isIconName } from '../Icon';
import { MagazineTile } from '../MagazineTile';

export interface MagazineAContentType {
  articles?: b2x.contentTypes.ContentSectionList<{
    articleCode?: string;
  }>;
  head?: {
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    title?: string;
  };
}

interface MagazineAProps extends MagazineAContentType {
  mainComponent?: boolean;
}

export const MagazineA = ({ articles, head, mainComponent }: MagazineAProps) => {
  return (
    <b2x.Div className="magazine-a" paddingY={{ lg: 5, xs: 3 }}>
      <Container>
        <b2x.Div
          className="component-head"
          marginBottom={{
            lg: 4,
            xs: 3,
          }}
          textAlign={'center'}
        >
          <b2x.ConditionalWrapper
            condition={head?.title !== undefined}
            wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
          >
            {b2x.formatHtml(head?.title)}
          </b2x.ConditionalWrapper>
          {head?.content && (
            <b2x.P className="content" marginBottom={{ lg: 3 }}>
              {b2x.formatHtml(head.content)}
            </b2x.P>
          )}
          {head?.cta?.label && (
            <b2x.Div display={{ lg: 'block', xs: 'none' }}>
              <b2x.CtaFromContent {...head.cta} variant="link" />
            </b2x.Div>
          )}
        </b2x.Div>
        <b2x.Div className="articles">
          <b2x.EqualHeight>
            <b2x.SwiperContext>
              {({ navigationNextElRef, navigationPrevElRef, swiper }) => (
                <b2x.Div className="swiper-container">
                  <b2x.Div>
                    <b2x.Swiper
                      breakpoints={{
                        lg: {
                          slidesPerView: 3,
                        },
                        md: {
                          slidesPerView: 2.3,
                        },
                        xs: {
                          slidesPerView: 1,
                        },
                      }}
                      navigation={{ custom: true }}
                      pagination
                      slides={articles?.map(({ articleCode, contentSectionId }) => (
                        <MagazineATile articleCode={articleCode} key={contentSectionId} />
                      ))}
                      spaceBetween={20}
                      watchSlidesProgress
                    />
                  </b2x.Div>
                  {b2x.appConfig.icons?.slider?.arrow?.left && isIconName(b2x.appConfig.icons.slider.arrow.left) && (
                    <b2x.Div display={swiper?.isBeginning ? 'none' : 'block'}>
                      <Button
                        className="swiper-arrow swiper-arrow-left"
                        iconStart={{ name: b2x.appConfig.icons.slider.arrow.left, size: 34 }}
                        innerRef={navigationPrevElRef}
                        variant="blank"
                      />
                    </b2x.Div>
                  )}
                  {b2x.appConfig.icons?.slider?.arrow?.right && isIconName(b2x.appConfig.icons.slider.arrow.right) && (
                    <b2x.Div display={swiper?.isEnd ? 'none' : 'block'}>
                      <Button
                        className="swiper-arrow swiper-arrow-right"
                        iconStart={{ name: b2x.appConfig.icons.slider.arrow.right, size: 34 }}
                        innerRef={navigationNextElRef}
                        variant="blank"
                      />
                    </b2x.Div>
                  )}
                </b2x.Div>
              )}
            </b2x.SwiperContext>
          </b2x.EqualHeight>
          {head?.cta?.label && (
            <b2x.Div display={{ lg: 'none' }} marginTop={2} textAlign={'center'}>
              <b2x.CtaFromContent {...head.cta} variant="outline-primary" />
            </b2x.Div>
          )}
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};

interface MagazineATileProps {
  articleCode?: string;
  index?: number;
}

const MagazineATile = ({ articleCode, index }: MagazineATileProps) => {
  const article = b2x.useContent<MagazineArticlePageContentType>(articleCode);

  return <React.Fragment>{article && <MagazineTile article={article} index={0} />}</React.Fragment>;
};
