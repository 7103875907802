import classnames from 'classnames';

import { ImageContentSectionV2 } from './contentTypes';
import { ImageAsBackgroundProps } from './ImageAsBackground';
import { ImageFromContentV2 } from './ImageFromContentV2';

export interface ImageAsBackgroundFromContentV2Props
  extends ImageContentSectionV2,
    Omit<ImageAsBackgroundProps, 'src' | 'backgroundSize'> {
  backgroundSize?: 'cover' | 'contain' | 'initial';
}

export const ImageAsBackgroundFromContentV2 = ({
  backgroundSize = 'cover',
  className,
  provider,
}: ImageAsBackgroundFromContentV2Props) => {
  return (
    <div
      className={classnames('image-as-background', className)}
      style={{ height: '100%', overflow: 'hidden', position: 'relative', width: '100%' }}
    >
      <ImageFromContentV2
        provider={provider}
        style={{ height: '100%', left: 0, objectFit: backgroundSize, position: 'absolute', top: 0, width: '100%' }}
      />
    </div>
  );
};
