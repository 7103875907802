import './SearchPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { Container } from '../Container';
import { SearchPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { HeaderA } from '../pagebuilder/HeaderA';
import { ProductTile } from '../ProductTile';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { categoriesListing, page, searchResult } = b2x.useSearch<SearchPageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: {
          content: true,
        },
        children: true,
        content: true,
      },
    },
  });

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  const [mobileColumn, setMobileColumn] = React.useState<string>('multiple');

  const handleSetMobileListingColumn = React.useCallback((column: 'single' | 'multiple') => {
    setMobileColumn(column);
  }, []);

  const { headerHeight } = useAppContext();
  const currentBreakpoint = b2x.useBreakpoint();

  // const headerBannerContent = b2x.useAncestorContent<SearchPageContentType>(
  //   {
  //     defaultContent: b2x.useContent<SearchPageContentType>('SEARCH_PAGE_CONTENT'),
  //     page,
  //   },
  //   (value) => !!value.content?.body.header
  // );

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="search-page" noPaddingBottom noPaddingTop>
          {MobileSearchFiltersOffcanvas}

          <b2x.Div className="breadcrumb-container" paddingY={3} style={{ zIndex: 10 }}>
            <Container>
              <b2x.Breadcrumb />
            </Container>
          </b2x.Div>

          {categoriesListing && categoriesListing.length > 1 && (
            <Container className="d-lg-none">
              <b2x.Div className="d-flex flex-row flex-nowrap mb-2 list-links">
                {categoriesListing.map((item, index) => (
                  <b2x.router.NavLink
                    className={classnames('btn btn-sm btn-outline-primary fw-normal text-capitalize me-2 extra-small', {
                      active: item.code === page?.code,
                    })}
                    end
                    key={item.id}
                    to={item.fullPath}
                  >
                    {index === 0 ? t('misc.viewAll') : item.name}
                  </b2x.router.NavLink>
                ))}
              </b2x.Div>
            </Container>
          )}
          {page?.content?.body.header ? (
            <HeaderA mainComponent {...page.content.body.header} className="mb-lg-4" />
          ) : (
            <HeaderA
              className="mb-lg-4"
              copy={{ options: { alignment: 'center' }, subtitle: page?.description, title: page?.name }}
              mainComponent
            />
          )}
          <section className="search-content">
            <Container>
              <div className="position-relative">
                <b2x.Row>
                  <b2x.Col className="px-0 px-lg-3" size={{ lg: 4, xl: 3, xs: 12 }}>
                    <div
                      className="search-filters-container mb-3 pe-lg-3"
                      style={
                        !b2x.untilBreakpoint('md', currentBreakpoint)
                          ? {
                              height: `calc(100vh - ${headerHeight}px)`,
                              top: headerHeight,
                            }
                          : undefined
                      }
                    >
                      <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                        {({ fieldsHelper, formik }) => (
                          <>
                            <div className="d-grid d-sm-block d-lg-none">
                              <Button
                                className="show-mobile-search-filter w-100"
                                onClick={showMobileSearchFiltersOffcanvas}
                                type="button"
                                variant="link"
                              >
                                <div className="m-auto">
                                  <div className="me-3">
                                    <Icon className="me-2" name="filter" size={20} />
                                    {t('misc.filterAndOrderBy')}
                                  </div>
                                </div>
                              </Button>
                            </div>
                            <b2x.Div className="search-form-active-filter d-none d-xl-block">
                              {fieldsHelper.activeFilters.length > 0 && (
                                <b2x.Row className="mb-3" gap={{ md: 1, xs: 2 }}>
                                  {fieldsHelper.activeFilters.map((activeFilter, index) => (
                                    <b2x.Col
                                      key={activeFilter.filter.name + activeFilter.filter.id}
                                      size={{ lg: 12, xs: 'auto' }}
                                    >
                                      {activeFilter.fromSimpleSearch && (
                                        <div className="py-1">{t('misc.youSearchedFor')}</div>
                                      )}
                                      <div className="d-grid">
                                        <Button
                                          className={classnames('btn-sm px-2 extra-small py-1')}
                                          iconEnd={{ name: 'close', size: 10 }}
                                          justifyContent="between"
                                          label={activeFilter.filter.name}
                                          onClick={activeFilter.handleClick}
                                          type="button"
                                          variant="gray-100"
                                        />
                                      </div>
                                    </b2x.Col>
                                  ))}
                                </b2x.Row>
                              )}
                            </b2x.Div>
                            <div className="mb-3">
                              <b2x.SearchFilters fieldsHelper={fieldsHelper} />
                            </div>
                          </>
                        )}
                      </b2x.SearchFormHelper>
                      {page?.description && (
                        <div className="small mb-5 d-none d-lg-block">{b2x.formatHtml(page.description)}</div>
                      )}
                    </div>
                  </b2x.Col>
                  <b2x.Col size={{ lg: 8, xl: 9, xs: 12 }}>
                    {searchResult?.itemsCount && (
                      <b2x.Row className="justify-content-between mb-3">
                        <b2x.Col size={'auto'}>
                          <div className="mb-2">{t('misc.searchItemResult', { count: searchResult.itemsCount })}</div>
                        </b2x.Col>
                        <b2x.Col size={'auto'}>
                          <SortingOptionsDropdown className="d-none d-lg-block mb-2" searchResult={searchResult} />
                          <div className="d-block d-md-none">
                            <div className="hstack gap-2">
                              <Button
                                className="p-2"
                                iconStart={{ name: 'two-column-display', size: 16 }}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => handleSetMobileListingColumn('multiple')}
                                variant="blank"
                              />
                              <div className="vr"></div>
                              <Button
                                className="p-2"
                                iconStart={{ name: 'one-column-display', size: 16 }}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => handleSetMobileListingColumn('single')}
                                variant="blank"
                              />
                            </div>
                          </div>
                        </b2x.Col>
                      </b2x.Row>
                    )}

                    {searchResult && searchResult.items && (
                      <b2x.Listing name="Search page" products={searchResult.items}>
                        <b2x.EqualHeight>
                          <b2x.Row
                            cols={{
                              lg: 2,
                              md: 3,
                              sm: mobileColumn === 'multiple' ? 2 : 1,
                              xl: 3,
                              xs: mobileColumn === 'multiple' ? 2 : 1,
                              xxl: 3,
                            }}
                            gap={{ lg: 4, xs: 2 }}
                          >
                            {searchResult.items.map((product, index) => (
                              <React.Fragment key={product.id}>
                                {index === 6 &&
                                  searchResult.pageNumber === 1 &&
                                  (page?.content?.body.productBanner?.title ||
                                    page?.content?.body.productBanner?.content ||
                                    page?.content?.body.productBanner?.cta?.label) && (
                                    <b2x.Col className="product-banner-col">
                                      <ProductBanner page={page} />
                                    </b2x.Col>
                                  )}
                                <b2x.Col>
                                  <ProductTile colIndex={index} product={product} />
                                </b2x.Col>
                              </React.Fragment>
                            ))}
                          </b2x.Row>
                        </b2x.EqualHeight>
                      </b2x.Listing>
                    )}
                    {searchResult && (
                      <b2x.Div className="my-3 my-lg-5" display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
                        <b2x.Pagination
                          currentPage={searchResult.pageNumber}
                          pageOffset={2}
                          showDots
                          singleStepNavigation
                          totalPages={searchResult.pagesCount}
                        />
                      </b2x.Div>
                    )}
                    {page?.content?.body.seoText && (
                      <div className="small my-lg-5 my-4">{b2x.formatHtml(page.content.body.seoText)}</div>
                    )}
                  </b2x.Col>
                </b2x.Row>
              </div>
            </Container>
          </section>
        </Page>
      )}
    </>
  );
};

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="gap-2 fw-bold text-lowercase"
          className={classnames('order-dropdown small', className)}
          iconStart={{ name: 'order', size: 18 }}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: ${sortingOptions.find((sortingItem) => sortingItem.active)?.label}`
          )}
          variant="outline-primary"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};

interface ProductBannerProps {
  page?: b2x.PageApiDto<SearchPageContentType> | undefined;
}

const ProductBanner = ({ page }: ProductBannerProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <b2x.Row gap={0}>
      {page?.content?.body.productBanner?.asset && (
        <b2x.Col size={{ lg: 8, xs: 12 }}>
          <b2x.AssetV2 className="w-100" fluid {...page.content.body.productBanner.asset} />
        </b2x.Col>
      )}
      {(page?.content?.body.productBanner?.title ||
        page?.content?.body.productBanner?.content ||
        page?.content?.body.productBanner?.cta) && (
        <b2x.Col size={{ lg: 4, xs: 12 }}>
          <b2x.Div
            alignItems={'center'}
            background={page.content.body.productBanner.background}
            className="h-100 w-100"
            display={'flex'}
          >
            <b2x.Div className="text-center" padding={3}>
              {page.content.body.productBanner.title && (
                <b2x.Div
                  className={classnames(
                    { h5: b2x.untilBreakpoint('sm', currentBreakpoint) },
                    { h2: !b2x.untilBreakpoint('sm', currentBreakpoint) },
                    ' fw-medium text-uppercase'
                  )}
                  marginBottom={{ lg: 5, xs: 3 }}
                >
                  {b2x.formatHtml(page.content.body.productBanner.title)}
                </b2x.Div>
              )}
              {page.content.body.productBanner.content && (
                <b2x.Div marginBottom={{ lg: 5, xs: 3 }}>
                  {b2x.formatHtml(page.content.body.productBanner.content)}
                </b2x.Div>
              )}
              {page.content.body.productBanner.cta && (
                <b2x.CtaFromContent {...page.content.body.productBanner.cta} variant="outline-primary" />
              )}
            </b2x.Div>
          </b2x.Div>
        </b2x.Col>
      )}
    </b2x.Row>
  );
};
