import './Header.scss';
import 'swiper/css/bundle';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import srcLogoMobile from './images/logo-mobile.svg';
import srcLogoRegular from './images/logo-regular.svg';
import { MobileHeader } from './MobileHeader';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderFirstRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderFirstRowRef.current) {
        setHeaderHeight(desktopHeaderFirstRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderFirstRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {!headerCheckout ? (
        <>
          <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          <MobileHeader
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            content={content}
            firstRowRef={desktopHeaderFirstRowRef}
            localeBoxVisible={localeBoxVisible}
            recalculateHeaderHeight={recalculateHeaderHeight}
            searchBoxVisible={searchBoxVisible}
            setActiveCategory={setActiveCategory}
            setLocaleBoxVisible={setLocaleBoxVisible}
            setSearchBoxVisible={setSearchBoxVisible}
            toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            visibleFrom="xl"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          logo={srcLogoRegular}
          logoSmall={srcLogoMobile}
          mobileFrom={'xl'}
          recalculateHeaderHeight={recalculateHeaderHeight}
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
}

const TopBar = ({ content, toggleLocaleBoxVisible }: TopBarProps) => {
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();
  const { topBarRowRef } = useAppContext();
  const { locale } = b2x.useAppContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);
  const slider = content?.body.topBar?.centerColumn?.messagesSlider;

  return (
    <div className={'top-bar'} ref={setTopBarRowRef}>
      <div className="bg-primary text-white py-1">
        <Container>
          <b2x.Row>
            <b2x.Col className="d-none d-xl-flex justify-content-start align-items-center" size={{ xl: 3, xs: 12 }}>
              <div className="hstack gap-3">
                <Button
                  className={'btn-sm px-0 text-initial fw-normal extra-small me-4'}
                  iconStart={{ name: 'language', size: 16 }}
                  label={locale && <span>{locale.languageCode}</span>}
                  onClick={toggleLocaleBoxVisible}
                />
                {content?.body.topBar?.leftColumn?.ctaList?.map(({ contentSectionId, cta }) => (
                  <b2x.CtaFromContent
                    key={contentSectionId}
                    {...cta}
                    ctaProps={{
                      button: {
                        className: 'btn-blank text-initial fw-normal extra-small text-light',
                        iconStart: cta?.icon ? { name: cta.icon, size: 16 } : undefined,
                      },
                    }}
                  />
                ))}
              </div>
            </b2x.Col>
            <b2x.Col className="d-flex justify-content-center align-items-center" size={{ xl: 6, xs: 12 }}>
              <div className="top-bar-swiper w-100 text-center">
                <b2x.SwiperContext>
                  {({ navigationNextElRef, navigationPrevElRef }) => (
                    <div className="position-relative">
                      <div
                        className="position-absolute top-0 start-0 h-100 d-flex align-items-center"
                        style={{ zIndex: 100 }}
                      >
                        <Button
                          className="px-0"
                          iconStart={{ name: 'arrow-left-light', size: 12 }}
                          innerRef={navigationPrevElRef}
                          justifyContent="start"
                        />
                      </div>
                      <b2x.SwiperFromContent
                        {...slider?.options}
                        direction="horizontal"
                        navigation={{ custom: true }}
                        slides={slider?.slides?.map(
                          ({ contentSectionId, cta }) =>
                            cta?.label && (
                              <React.Fragment key={contentSectionId}>
                                <b2x.CtaFromContent
                                  ctaProps={{
                                    button: {
                                      className: 'text-white text-reset text-initial btn-sm extra-small px-0',
                                      variant: 'blank',
                                    },
                                  }}
                                  {...cta}
                                />
                              </React.Fragment>
                            )
                        )}
                        slidesPerView={1}
                        style={{ flex: 1 }}
                      />
                      <div
                        className="position-absolute top-0 end-0 h-100 d-flex align-items-center"
                        style={{ zIndex: 100 }}
                      >
                        <Button
                          className="px-0"
                          iconStart={{ name: 'arrow-right-light', size: 12 }}
                          innerRef={navigationNextElRef}
                          justifyContent="end"
                        />
                      </div>
                    </div>
                  )}
                </b2x.SwiperContext>
              </div>
            </b2x.Col>
            <b2x.Col className="d-none d-xl-flex justify-content-end align-items-center" size={{ xl: 3, xs: 12 }}>
              <div className="extra-small">{b2x.formatHtml(content?.body.topBar?.rightColumn?.label)}</div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
    </div>
  );
};
