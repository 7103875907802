import { SetRequired } from 'type-fest';

import { VideoContentSectionV2, VideoVariantContentSectionV2 } from './contentTypes';
import { getItemFromSwitch } from './util';
import { Video, VideoProps, VideoVariant } from './Video';

export interface VideoFromContentPropsV2 extends VideoContentSectionV2, Omit<VideoProps, 'variants' | 'options'> {
  className?: string;
}

const fromVariantContentSectionToVideoVariant = (
  variantContentSection: SetRequired<VideoVariantContentSectionV2, 'source' | 'width' | 'height'>
): VideoVariant => {
  const _source = getItemFromSwitch(variantContentSection.source);

  return {
    height: variantContentSection.height,
    source: {
      bcom: _source?.bcom?.file
        ? {
            file: _source.bcom.file,
          }
        : undefined,
      external: _source?.external?.file
        ? {
            file: _source.external.file,
          }
        : undefined,
      vimeo: _source?.vimeo?.id
        ? {
            id: _source.vimeo.id,
            privacyHash: _source.vimeo.privacyHash,
            title: _source.vimeo.title,
          }
        : undefined,
      youtube: _source?.youtube?.id
        ? {
            id: _source.youtube.id,
          }
        : undefined,
    },
    thumbnail: variantContentSection.thumbnail?.url,
    width: variantContentSection.width,
  };
};

export const VideoFromContentV2 = ({ className, options, variants, ...otherVideoProps }: VideoFromContentPropsV2) => {
  return (
    <>
      {variants?.xs?.source && variants.xs.width && variants.xs.height && (
        <Video
          {...otherVideoProps}
          className={className}
          options={options}
          variants={{
            lg:
              variants.lg?.source && variants.lg.width && variants.lg.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.lg.height,
                    source: variants.lg.source,
                    thumbnail: variants.lg.thumbnail,
                    width: variants.lg.width,
                  })
                : undefined,

            md:
              variants.md?.source && variants.md.width && variants.md.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.md.height,
                    source: variants.md.source,
                    thumbnail: variants.md.thumbnail,
                    width: variants.md.width,
                  })
                : undefined,

            sm:
              variants.sm?.source && variants.sm.width && variants.sm.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.sm.height,
                    source: variants.sm.source,
                    thumbnail: variants.sm.thumbnail,
                    width: variants.sm.width,
                  })
                : undefined,

            xl:
              variants.xl?.source && variants.xl.width && variants.xl.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.xl.height,
                    source: variants.xl.source,
                    thumbnail: variants.xl.thumbnail,
                    width: variants.xl.width,
                  })
                : undefined,

            xs: fromVariantContentSectionToVideoVariant({
              height: variants.xs.height,
              source: variants.xs.source,
              thumbnail: variants.xs.thumbnail,
              width: variants.xs.width,
            }),
            xxl:
              variants.xxl?.source && variants.xxl.width && variants.xxl.height
                ? fromVariantContentSectionToVideoVariant({
                    height: variants.xxl.height,
                    source: variants.xxl.source,
                    thumbnail: variants.xxl.thumbnail,
                    width: variants.xxl.width,
                  })
                : undefined,
          }}
        />
      )}
    </>
  );
};

export const isVideoFromContentValid = (contentSection?: VideoContentSectionV2) => {
  const _source = contentSection?.variants?.xs?.source && getItemFromSwitch(contentSection.variants.xs.source);

  return (
    contentSection?.variants?.xs?.width &&
    contentSection.variants.xs.height &&
    _source &&
    (_source.bcom?.file || _source.external?.file || _source.youtube?.id || _source.vimeo?.id)
  );
};
