import './AccordionA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';

export interface AccordionAContentType {
  body?: {
    accordionItems?: b2x.contentTypes.ContentSectionList<{
      tabItems?: b2x.contentTypes.ContentSectionList<{
        content?: string;
        productItems?: b2x.contentTypes.ContentSectionList<{
          id?: string;
          title?: string;
        }>;
        title?: string;
      }>;
      title?: string;
    }>;
  };
  foot?: {
    cta?: b2x.contentTypes.CtaContentSection;
  };
  head?: {
    content?: string;
    cta?: b2x.contentTypes.CtaContentSection;
    title?: string;
  };
}

interface AccordionAProps extends AccordionAContentType {
  mainComponent?: boolean;
}

export const AccordionA = ({ body, foot, head, mainComponent }: AccordionAProps) => {
  return (
    <b2x.Div className="accordion-a" paddingY={{ lg: 5, xs: 3 }}>
      {(head?.title || head?.content || head?.cta?.label) && (
        <b2x.Div
          className="component-head"
          marginBottom={{
            lg: 4,
            xs: 3,
          }}
          textAlign={'center'}
        >
          <Container>
            <b2x.ConditionalWrapper
              condition={head.title !== undefined}
              wrapper={mainComponent ? <b2x.H1 className="title" /> : <b2x.P className="title" />}
            >
              {b2x.formatHtml(head.title)}
            </b2x.ConditionalWrapper>
            {head.content && (
              <b2x.P className="content" marginBottom={{ lg: 3 }}>
                {b2x.formatHtml(head.content)}
              </b2x.P>
            )}
            {head.cta?.label && (
              <b2x.Div display={{ lg: 'block', xs: 'none' }}>
                <b2x.CtaFromContent {...head.cta} variant="link" />
              </b2x.Div>
            )}
          </Container>
        </b2x.Div>
      )}
      <b2x.Div
        className="component-body"
        marginBottom={{
          lg: 4,
          xs: 3,
        }}
      >
        <Container>
          {body?.accordionItems && body.accordionItems.length > 0 && (
            <b2x.Row justifyContent={'center'}>
              <b2x.Col size={{ xl: 9, xs: 12 }}>
                <b2x.Accordion id="accordion-a" itemButtonClassName="text-reset">
                  {body.accordionItems.map((accordionItem) => (
                    <b2x.AccordionItem
                      id={accordionItem.contentSectionId.toString()}
                      key={accordionItem.contentSectionId}
                      title={accordionItem.title ?? ''}
                    >
                      {accordionItem.tabItems && accordionItem.tabItems.length > 0 && (
                        <b2x.Div className="nav-tabs-container">
                          <AccordionContent accordionItem={accordionItem} />
                        </b2x.Div>
                      )}
                    </b2x.AccordionItem>
                  ))}
                </b2x.Accordion>
              </b2x.Col>
            </b2x.Row>
          )}
        </Container>
      </b2x.Div>
      {foot?.cta?.label && (
        <b2x.Div className="component-foot" textAlign={'center'}>
          <Container>
            <b2x.CtaFromContent {...foot.cta} variant="link" />
          </Container>
        </b2x.Div>
      )}
    </b2x.Div>
  );
};

interface AccordionContentProps {
  accordionItem: {
    tabItems?: b2x.contentTypes.ContentSectionList<{
      content?: string;
      productItems?: b2x.contentTypes.ContentSectionList<{
        id?: string;
        title?: string;
      }>;
      title?: string;
    }>;
    title?: string;
  };
}

const AccordionContent = ({ accordionItem }: AccordionContentProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<b2x.SwiperClass>();

  const [swiperTabContainer, scrollToSwiperTabContainer] = b2x.useScrollTo(-120);

  return (
    <b2x.Div className="accordion-content" innerRef={swiperTabContainer}>
      <b2x.Div className="swiper-tab-nav-container" marginBottom={{ lg: 5, xs: 3 }}>
        <b2x.SwiperContext>
          {(swiper) => (
            <b2x.Swiper
              loop={false}
              onSwiper={setThumbsSwiper}
              slidesPerView={'auto'}
              spaceBetween={16}
              watchSlidesProgress
            >
              {accordionItem.tabItems?.map((tabItem, index) => (
                <b2x.SwiperSlide
                  className={classnames({
                    'swiper-slide-disabled': tabItem.productItems && tabItem.productItems.length < 1,
                  })}
                  key={`tab-nav-${tabItem.contentSectionId}`}
                >
                  <b2x.Div className={classnames('tab-nav')}>
                    <div className="tab-nav-index">{(index + 1).toString().padStart(2, '0')}</div>
                    <div className="tab-nav-title">{tabItem.title}</div>
                  </b2x.Div>
                </b2x.SwiperSlide>
              ))}
            </b2x.Swiper>
          )}
        </b2x.SwiperContext>
      </b2x.Div>
      <b2x.Div className="swiper-tab-pane-container" marginBottom={{ lg: 5, xs: 3 }}>
        <b2x.SwiperContext>
          {(swiper) => (
            <b2x.Swiper
              allowTouchMove={false}
              draggable={false}
              effect="fade"
              onSlideChange={scrollToSwiperTabContainer}
              slides={accordionItem.tabItems?.map((tabItem) => (
                <b2x.Div key={`tap-pane-${tabItem.contentSectionId}`}>
                  {tabItem.content && (
                    <b2x.Div marginBottom={{ lg: 5, xs: 4 }} textAlign={'center'}>
                      {tabItem.content}
                    </b2x.Div>
                  )}
                  {tabItem.productItems && tabItem.productItems.length > 0 && (
                    <b2x.EqualHeight>
                      <b2x.ProductsByIds ids={tabItem.productItems.map((item) => item.id)}>
                        {(products) => (
                          <b2x.Listing name="accordion-a" products={products}>
                            <b2x.Row gap={3} justifyContent={{ lg: 'center' }}>
                              {products?.map((product, index) => (
                                <b2x.Col key={product.id} size={{ lg: 3, xs: 6 }}>
                                  {tabItem.productItems?.at(index)?.title && (
                                    <b2x.Div className="pre-product-title text-center" marginBottom={3}>
                                      <b2x.EqualHeightElement name="pre-product-title">
                                        {b2x.formatHtml(tabItem.productItems.at(index)?.title)}
                                      </b2x.EqualHeightElement>
                                    </b2x.Div>
                                  )}
                                  <b2x.ProductTile product={product} />
                                </b2x.Col>
                              ))}
                            </b2x.Row>
                          </b2x.Listing>
                        )}
                      </b2x.ProductsByIds>
                    </b2x.EqualHeight>
                  )}
                </b2x.Div>
              ))}
              slidesPerView={1}
              spaceBetween={16}
              thumbs={{ swiper: thumbsSwiper }}
            />
          )}
        </b2x.SwiperContext>
      </b2x.Div>
    </b2x.Div>
  );
};
