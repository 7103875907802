import './CopyE.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';

export interface CopyEContentType {
  assetA?: b2x.contentTypes.AssetContentSectionV2;
  assetB?: b2x.contentTypes.AssetContentSectionV2;
  background?: b2x.contentTypes.ContentSectionSwitch<{
    asset?: b2x.AssetAsBackgroundV2Props;
    color?: string;
  }>;
  contents?: b2x.contentTypes.ContentSectionList<{
    content?: string;
    title?: string;
  }>;
}

interface CopyEProps extends CopyEContentType {
  mainComponent?: boolean;
}

export const CopyE = ({ assetA, assetB, background, contents, mainComponent }: CopyEProps) => {
  const _background = b2x.getItemFromSwitch(background);

  return (
    <b2x.Div
      className={classnames('copy-e', { 'with-background': _background })}
      paddingY={_background ? undefined : { lg: 5, xs: 3 }}
      style={{ overflow: 'hidden', position: 'relative' }}
    >
      {_background && (
        <b2x.Div
          background={_background.color}
          className="background"
          style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
        >
          {_background.asset && <b2x.AssetAsBackgroundV2 backgroundSize="cover" {..._background.asset} />}
        </b2x.Div>
      )}
      <Container>
        <b2x.Div style={{ overflow: 'hidden', position: 'relative' }}>
          <b2x.Div paddingY={_background ? { lg: 5, xs: 5 } : 0} style={{ position: 'relative' }}>
            <b2x.Div className="grid-layout">
              <b2x.Div className="asset-a" marginBottom={{ md: 5, xs: 3 }}>
                <b2x.AssetV2 {...assetA} fluid />
              </b2x.Div>
              <b2x.Div className="copy">
                {contents?.map(({ content, contentSectionId, title }) => (
                  <React.Fragment key={contentSectionId}>
                    {title && <b2x.P className="title">{b2x.formatHtml(title)}</b2x.P>}
                    {content && <b2x.Div className="content">{b2x.formatHtml(content)}</b2x.Div>}
                  </React.Fragment>
                ))}
              </b2x.Div>
              <b2x.Div className="asset-b">
                <b2x.AssetV2 {...assetB} fluid />
              </b2x.Div>
            </b2x.Div>
          </b2x.Div>
        </b2x.Div>
      </Container>
    </b2x.Div>
  );
};
