import classnames from 'classnames';
import React from 'react';

export interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  fullHeight?: boolean;
}
export const Box = ({ children, className, fullHeight }: BoxProps) => {
  return (
    <div
      className={classnames(
        'bg-white border border-gray-200 shadow-sm px-3 px-xl-5 py-3 py-xl-5',
        {
          'h-100': fullHeight,
        },
        className
      )}
    >
      {children}
    </div>
  );
};
