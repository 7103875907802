import './ProductsSliderDoubleColumnA.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Button } from '../Button';
import { Container } from '../Container';
import { isIconName } from '../Icon';

export interface ProductColumnContainerContentType {
  copy?: {
    options?: {
      textAlign?: string;
      textAlignMobile?: string;
    };
    preTitle?: string;
    subTitle?: string;
    title?: string;
  };
  cta?: b2x.contentTypes.CtaContentSection;
  ids?: b2x.contentTypes.ContentSectionList<{ id: string }>;
  mobileCtaVariant?: string;
}

export interface ProductsSliderDoubleColumnAContentType {
  body?: {
    assetColumn?: {
      asset?: b2x.contentTypes.AssetContentSectionV2;
      ctaVariantAboveAsset?: string;
    };
    productsColumn?: ProductColumnContainerContentType;
  };
  head?: {
    preTitle?: string;
    title?: string;
  };
  mainComponent?: boolean;
  options?: {
    ordering?: {
      desktop?: string;
      mobile?: string;
    };
  };
}

export interface ProductsSliderDoubleColumnAProps extends ProductsSliderDoubleColumnAContentType {}

export const ProductsSliderDoubleColumnA = ({
  body,
  head,
  mainComponent,
  options,
}: ProductsSliderDoubleColumnAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  const desktopBeforeAsset = options?.ordering?.desktop === 'before asset';
  const mobileBeforeAsset = options?.ordering?.mobile === 'before asset';
  return (
    <b2x.Div className="products-slider-double-column-a" paddingY={{ lg: 5, xs: 3 }}>
      <Container>
        <b2x.Div
          className="component-head"
          display="flex"
          flexDirection="column"
          gap={2}
          marginBottom={{ lg: 4, xs: 3 }}
          textAlign="center"
        >
          {head?.preTitle && (
            <b2x.P className="pre-title" marginBottom={0}>
              {b2x.formatHtml(head.preTitle)}
            </b2x.P>
          )}
          <b2x.Row justifyContent="center">
            <b2x.Col size={{ md: 8, xs: 12 }}>
              <b2x.ConditionalWrapper
                condition={head?.title !== undefined}
                wrapper={
                  mainComponent ? (
                    <b2x.H1 className="title" marginBottom={0} />
                  ) : (
                    <b2x.P className="title" marginBottom={0} />
                  )
                }
              >
                {b2x.formatHtml(head?.title)}
              </b2x.ConditionalWrapper>
            </b2x.Col>
          </b2x.Row>
        </b2x.Div>
        <b2x.Div className="component-body">
          <b2x.Row>
            <b2x.Col
              className={classNames('asset-column-container', {
                'desktop-order-2': !desktopBeforeAsset,
                'mobile-order-2': mobileBeforeAsset,
              })}
              size={{ lg: 6, xs: 12 }}
            >
              {body?.assetColumn?.asset && (
                <b2x.router.Link
                  to={
                    body.productsColumn?.cta?.to?.href
                      ? body.productsColumn.cta.to.href
                      : body.productsColumn?.cta?.to?.code && getPagePath(body.productsColumn.cta.to.code)
                  }
                >
                  <b2x.Div className="asset-column" display="grid" marginBottom={{ lg: 0, xs: 3 }}>
                    <b2x.AssetV2 {...body.assetColumn.asset} fluid />
                    {!isMobile && body.assetColumn.ctaVariantAboveAsset && body.productsColumn?.cta && (
                      <b2x.Div marginBottom={4} marginTop="auto" marginX="auto">
                        <b2x.CtaFromContent
                          {...body.productsColumn.cta}
                          variant={body.assetColumn.ctaVariantAboveAsset}
                        />
                      </b2x.Div>
                    )}
                  </b2x.Div>
                </b2x.router.Link>
              )}
            </b2x.Col>
            <b2x.Col className="products-column-container" size={{ lg: 6, xs: 12 }}>
              <ProductColumnContainer
                ctaVariantAboveAsset={body?.assetColumn?.ctaVariantAboveAsset}
                {...body?.productsColumn}
              />
            </b2x.Col>
          </b2x.Row>
        </b2x.Div>

        {body?.productsColumn?.cta?.label && (
          <b2x.Div display={{ lg: 'none', xs: 'block' }} textAlign={'center'}>
            <b2x.CtaFromContent {...body.productsColumn.cta} variant={body.productsColumn.mobileCtaVariant} />
          </b2x.Div>
        )}
      </Container>
    </b2x.Div>
  );
};

interface ProductColumnContainerProps extends ProductColumnContainerContentType {
  ctaVariantAboveAsset?: string;
}

const ProductColumnContainer = ({ copy, cta, ctaVariantAboveAsset, ids }: ProductColumnContainerProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const isMobile = b2x.untilBreakpoint('md', currentBreakpoint);

  const textAlign: b2x.TextAlign =
    copy?.options?.textAlign === 'start'
      ? 'start'
      : copy?.options?.textAlign === 'center'
      ? 'center'
      : copy?.options?.textAlign === 'end'
      ? 'end'
      : 'start';

  const textAlignMobile: b2x.TextAlign =
    copy?.options?.textAlignMobile === 'start'
      ? 'start'
      : copy?.options?.textAlignMobile === 'center'
      ? 'center'
      : copy?.options?.textAlignMobile === 'end'
      ? 'end'
      : 'start';

  return (
    <>
      <b2x.Div
        display="flex"
        flexDirection="column"
        gap={1}
        marginBottom={2}
        paddingX={{ lg: 5, xs: 0 }}
        textAlign={{ lg: textAlign, xs: textAlignMobile }}
      >
        {copy?.preTitle && (
          <b2x.P className="pre-title" marginBottom={0}>
            {b2x.formatHtml(copy.preTitle)}
          </b2x.P>
        )}
        {copy?.title && (
          <b2x.P className="title" marginBottom={0}>
            {b2x.formatHtml(copy.title)}
          </b2x.P>
        )}
        {copy?.subTitle && (
          <b2x.P className="sub-title" marginBottom={2}>
            {b2x.formatHtml(copy.subTitle)}
          </b2x.P>
        )}
        {!isMobile && cta?.label && !ctaVariantAboveAsset && (
          <b2x.Div marginEnd={0} marginStart="auto">
            <b2x.CtaFromContent {...cta} />
          </b2x.Div>
        )}
      </b2x.Div>
      {ids?.length && (
        <b2x.Div
          className="products-swiper-container"
          marginBottom={cta?.label ? 3 : undefined}
          paddingX={{ lg: 5, xs: 0 }}
        >
          <b2x.ProductsByIds ids={ids.map(({ id }) => id)}>
            {(products) => (
              <b2x.EqualHeight>
                <b2x.Listing name="Landing template B products" products={products}>
                  <b2x.SwiperContext>
                    {({ navigationNextElRef, navigationPrevElRef, paginationElRef, swiper }) => (
                      <>
                        <b2x.Swiper
                          breakpoints={{
                            md: {
                              slidesPerView: 2,
                            },
                            xs: {
                              slidesPerView: 1.2,
                            },
                          }}
                          centerInsufficientSlides
                          navigation={{ custom: true }}
                          pagination={{ custom: true }}
                          slides={products?.map((product) => (
                            <b2x.ProductTile key={product.id} product={product} />
                          ))}
                          spaceBetween={10}
                          watchSlidesProgress
                        />
                        <b2x.Div
                          className="position-absolute w-100 top-0 start-0"
                          display={{ lg: 'block', xs: 'none' }}
                        >
                          <b2x.EqualHeightElement name={'product-media-container'}>
                            {b2x.appConfig.icons?.slider?.arrow?.left &&
                              isIconName(b2x.appConfig.icons.slider.arrow.left) && (
                                <b2x.Div display={swiper?.isBeginning ? 'none' : 'block'}>
                                  <Button
                                    className="swiper-arrow swiper-arrow-left"
                                    iconStart={{ name: b2x.appConfig.icons.slider.arrow.left, size: 34 }}
                                    innerRef={navigationPrevElRef}
                                    variant="blank"
                                  />
                                </b2x.Div>
                              )}
                            {b2x.appConfig.icons?.slider?.arrow?.right &&
                              isIconName(b2x.appConfig.icons.slider.arrow.right) && (
                                <b2x.Div display={swiper?.isEnd ? 'none' : 'block'}>
                                  <Button
                                    className="swiper-arrow swiper-arrow-right"
                                    iconStart={{ name: b2x.appConfig.icons.slider.arrow.right, size: 34 }}
                                    innerRef={navigationNextElRef}
                                    variant="blank"
                                  />
                                </b2x.Div>
                              )}
                          </b2x.EqualHeightElement>
                        </b2x.Div>
                        <b2x.Div className="swiper-pagination" innerRef={paginationElRef}></b2x.Div>
                      </>
                    )}
                  </b2x.SwiperContext>
                </b2x.Listing>
              </b2x.EqualHeight>
            )}
          </b2x.ProductsByIds>
        </b2x.Div>
      )}
    </>
  );
};
