import './MobileSearchOffcanvas.scss';

import { b2x } from '@b2x/react/src';

import { Button } from './Button';

interface MobileSearchOffcanvasProps extends b2x.OffcanvasProps {}

const MobileSearchOffcanvas = ({ ...offcanvasProps }: MobileSearchOffcanvasProps) => {
  return (
    <b2x.Offcanvas className="mobile-simple-search-offcanvas" placement="top" {...offcanvasProps}>
      {({ close }) => (
        <>
          <div className={'offcanvas-header-wrapper p-3 pb-2'}>
            <div className={'offcanvas-header justify-content-end p-0'}>
              <Button
                aria-label="Close"
                className="p-2"
                data-bs-dismiss="offcanvas"
                iconEnd={{ name: 'close', size: 22 }}
                type="button"
                variant="blank"
              />
            </div>
          </div>
          <b2x.OffcanvasBody className="pb-4">
            <b2x.template.TopSuggestions clearIconName="close" onSuccess={close} submitIconName="search" />
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

export const useMobileSearchOffcanvas = (props?: MobileSearchOffcanvasProps) =>
  b2x.useStaticModal(MobileSearchOffcanvas, 'offcanvas', props);
