import './ServicePage.scss';

import { b2x } from '@b2x/react/src';

import { Page } from './Page';

export interface ServicePageProps {}

export const ServicePage = (props: ServicePageProps) => {
  return (
    <Page className="service-page" nudeBackground>
      <b2x.ServicePage {...props} />
    </Page>
  );
};
