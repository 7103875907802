import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuDesktopItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import { Logo } from './Logo';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  content,
  firstRowRef,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  toggleLocaleBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER_DESKTOP: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, [setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  return (
    <React.Fragment>
      <div
        className={classnames(`desktop-header-first-row bg-white py-3 d-none d-${visibleFrom}-block`)}
        ref={firstRowRef}
      >
        <Container>
          <b2x.Row gap={0}>
            <b2x.Col size={''}>
              {content?.body.mainBar?.leftColumn?.ctaList?.length &&
                content.body.mainBar.leftColumn.ctaList.length > 0 && (
                  <b2x.Div alignItems={'start'} display={'flex'} justifyContent={'start'} paddingTop={2}>
                    <div className="hstack gap-3">
                      {content.body.mainBar.leftColumn.ctaList.map(({ contentSectionId, cta }) => (
                        <b2x.CtaFromContent
                          key={contentSectionId}
                          {...cta}
                          ctaProps={{
                            button: {
                              className: 'btn-blank text-initial fw-normal small text-dark',
                              iconStart: cta?.icon ? { name: cta.icon, size: 22 } : undefined,
                            },
                          }}
                        />
                      ))}
                    </div>
                  </b2x.Div>
                )}
            </b2x.Col>
            <b2x.Col size={'auto'}>
              <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="regular" />
            </b2x.Col>
            <b2x.Col size={''}>
              <b2x.Div alignItems={'start'} display={'flex'} justifyContent={'end'}>
                <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
              </b2x.Div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      <b2x.Sticky className={classnames(`desktop-header-second-row d-none d-${visibleFrom}-block`)} offset={-1}>
        {({ isSticky }) => (
          <React.Fragment>
            <div className="position-relative bg-white shadow-sm" style={{ zIndex: 100 }}>
              <Container>
                <b2x.Row alignItems={'center'}>
                  <b2x.Col size={'auto'}>
                    <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="sticky" />
                  </b2x.Col>
                  <b2x.Col size={''}>
                    <b2x.Div display={'flex'} justifyContent={isSticky ? 'start' : 'center'}>
                      <Menu
                        activeCategory={activeCategory}
                        isSticky={isSticky}
                        menu={menu}
                        setActiveCategory={setActiveCategory}
                        setLocaleBoxVisible={setLocaleBoxVisible}
                        setSearchBoxVisible={setSearchBoxVisible}
                        subMenuDropdownRef={subMenuDropdownRef}
                      />
                    </b2x.Div>
                  </b2x.Col>
                  <b2x.Col size={'auto'}>
                    <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
                  </b2x.Col>
                </b2x.Row>
              </Container>
            </div>

            {activeCategory && (
              <SubMenuDropdown
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                subMenuDropdownRef={subMenuDropdownRef}
              />
            )}
            {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
            {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
          </React.Fragment>
        )}
      </b2x.Sticky>
    </React.Fragment>
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <div className="toggles">
      <div className="hstack gap-2">
        <Button
          className="p-2"
          iconEnd={{ name: 'search', size: 22 }}
          onClick={toggleSearchBoxVisible}
          variant="blank"
        />
        {session?.customer ? (
          <b2x.Cta
            button={{ className: 'p-2', iconStart: { name: 'account-logged-in', size: 22 }, variant: 'blank' }}
            code="SITE_ACCOUNT"
          />
        ) : (
          <Button
            className="p-2"
            iconEnd={{ name: 'account', size: 22 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="btn-wishlist" to="/account/area/wishlist">
              <Button className="position-relative p-2" variant="blank">
                <Icon name={'wishlist'} size={22} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button
              className="btn-wishlist p-2"
              iconEnd={{ name: 'wishlist', size: 22 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          ))}
        <Button className="btn-cart position-relative p-2" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'shopping-cart'} size={22} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <b2x.router.Link
        className={classnames('p-2 hstack gap-1 position-relative', firstLevelCategory.cssClass)}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        to={firstLevelCategory.link}
      >
        {isIconName(firstLevelCategory.iconName) && <Icon name={firstLevelCategory.iconName} size={20} />}
        <span className="">{firstLevelCategory.label}</span>
      </b2x.router.Link>
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  isSticky?: boolean;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setLocaleBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({
  activeCategory,
  isSticky,
  menu,
  setActiveCategory,
  setLocaleBoxVisible,
  setSearchBoxVisible,
  subMenuDropdownRef,
}: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
      setLocaleBoxVisible(false);
      setSearchBoxVisible(false);
    }
  }, [idActiveCategory, setActiveCategory, setLocaleBoxVisible, setSearchBoxVisible]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 250);

  return (
    <div className="menu">
      <b2x.Row className={classnames({ 'gx-1': isSticky }, { 'gx-3': !isSticky })}>
        {menu?.children.map(
          (firstLevelCategory) =>
            // Togliere il controlo sulla label e rimetterlo sul link
            (firstLevelCategory.children.length > 0 || firstLevelCategory.label !== undefined) && (
              <b2x.Col className="text-uppercase small fw-bold" key={firstLevelCategory.id} size={'auto'}>
                {firstLevelCategory.children.length > 0 ? (
                  <DropdownMenuButton
                    activeCategory={activeCategory}
                    addRef={addRef}
                    firstLevelCategory={firstLevelCategory}
                    key={firstLevelCategory.id}
                    removeRef={removeRef}
                    setActiveCategory={setActiveCategory}
                  />
                ) : (
                  <MenuItemContainer
                    activeCategory={activeCategory}
                    firstLevelCategory={firstLevelCategory}
                    key={firstLevelCategory.id}
                  >
                    <b2x.router.Link
                      className={classnames(
                        {
                          active: activeCategory && activeCategory.id === firstLevelCategory.id,
                        },
                        firstLevelCategory.cssClass,
                        'p-2 hstack gap-1 position-relative text-decoration-none'
                      )}
                      to={firstLevelCategory.link}
                    >
                      {isIconName(firstLevelCategory.iconName) && <Icon name={firstLevelCategory.iconName} size={20} />}
                      <span>{firstLevelCategory.label}</span>
                    </b2x.router.Link>
                  </MenuItemContainer>
                )}
              </b2x.Col>
            )
        )}
      </b2x.Row>
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div
      className={classnames({ mounted: isMounted }, 'sub-menu-dropdown position-absolute w-100 bg-white shadow-sm')}
      ref={subMenuDropdownRef}
    >
      <Container>
        <b2x.Row gap={0}>
          {activeCategory.children.length === 3
            ? activeCategory.children.map((secondLevelCategory, index) => (
                <React.Fragment key={secondLevelCategory.id}>
                  {index === 0 ? (
                    // Featured column
                    <b2x.Col className="bg-nude py-5 px-4" size={2}>
                      <b2x.Row>
                        {secondLevelCategory.children.map((item) => (
                          <b2x.Col key={item.id} size={12}>
                            <RecursiveMenuItem close={close} deep={2} item={item} />
                          </b2x.Col>
                        ))}
                      </b2x.Row>
                    </b2x.Col>
                  ) : index === 1 ? (
                    // Menu item column
                    <b2x.Col className="pt-5 px-4 pb-3" size={6}>
                      <b2x.Row>
                        {secondLevelCategory.children.map((item) => (
                          <b2x.Col key={item.id}>
                            <RecursiveMenuItem close={close} deep={2} item={item} />
                          </b2x.Col>
                        ))}
                        <b2x.Col size={12}>
                          <div className="pt-5 mt-5">
                            <b2x.router.Link
                              className="fs-5 fw-bold text-decoration-underline"
                              to={secondLevelCategory.link}
                            >
                              {secondLevelCategory.note}
                            </b2x.router.Link>
                          </div>
                        </b2x.Col>
                      </b2x.Row>
                    </b2x.Col>
                  ) : (
                    index > 1 && (
                      // Media column
                      <b2x.Col className="pb-5 px-3" size={4}>
                        <b2x.Row>
                          {secondLevelCategory.children.map((item) => (
                            <b2x.Col key={item.id}>
                              <RecursiveMenuItem close={close} deep={2} item={item} />
                            </b2x.Col>
                          ))}
                        </b2x.Row>
                      </b2x.Col>
                    )
                  )}
                </React.Fragment>
              ))
            : activeCategory.children.map((secondLevelCategory, index) => (
                <b2x.Col key={secondLevelCategory.id}>
                  {secondLevelCategory.children.length > 0 && (
                    <RecursiveMenuItem close={close} item={secondLevelCategory} />
                  )}
                </b2x.Col>
              ))}
        </b2x.Row>
      </Container>
    </div>
  );
};

interface RecursiveMenuItemProps {
  close?(): void;
  deep?: number;
  item?: b2x.MenuApiDto;
}

const RecursiveMenuItem = ({ close, deep = 1, item }: RecursiveMenuItemProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <div className={classnames(`deep-${deep}`, item?.code)}>
      {item?.content && item.content.type === 'HEADER_MENU_DESKTOP_ITEM_CONTENT_TYPE'
        ? b2x.typedContent<HeaderMenuDesktopItemContentType>(item.content, (content) => (
            <div className="mt-5">
              {content.body.asset && (
                <div className="mb-3">
                  <b2x.AssetV1 {...content.body.asset} fluid />
                </div>
              )}
              {content.body.cta && (
                <div>
                  <b2x.router.Link
                    className="fw-bold text-decoration-underline text-start fs-6"
                    to={
                      content.body.cta.to?.href
                        ? content.body.cta.to.href
                        : content.body.cta.to?.code && getPagePath(content.body.cta.to.code)
                    }
                  >
                    <span className="me-2">{b2x.formatHtml(content.body.cta.label)}</span>
                    <Icon name={'arrow-right-bold'} size={12} />
                  </b2x.router.Link>
                </div>
              )}
            </div>
          ))
        : item && (
            <div>
              <b2x.ConditionalWrapper
                condition={item.link !== undefined}
                wrapper={<b2x.router.Link onClick={close} to={item.link} />}
              >
                {item.image && (
                  <div>
                    <b2x.Image {...item.image} exclude2x={false} fluid />
                  </div>
                )}
                <h6>
                  <b2x.router.Link
                    className={classnames('text-decoration-underline fw-bold', {
                      'fs-5': item.children.length === 0,
                      'text-uppercase': item.children.length > 0,
                    })}
                    onClick={close}
                    to={item.link}
                  >
                    {item.label}
                  </b2x.router.Link>
                </h6>
              </b2x.ConditionalWrapper>
              {item.children.length > 0 && (
                <ul className="list-unstyled m-0">
                  {item.children.map((child) => (
                    <li key={child.id}>
                      <b2x.router.Link className="small text-decoration-none" onClick={close} to={child.link}>
                        {child.label}
                      </b2x.router.Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box className="search-box" onCloseButtonClick={toggleSearchBoxVisible}>
      <b2x.template.TopSuggestions clearIconName="close" onSuccess={toggleSearchBoxVisible} submitIconName="search" />
    </Box>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  const { info } = b2x.useAppContext();

  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <Container>
        <b2x.Row justifyContent={'center'}>
          <b2x.Col size={6}>
            <b2x.LocaleForm className="mb-3" />
            {info?.shippingCountries?.length && info.shippingCountries.length > 1 && (
              <div className="text-center">{t('misc.changeCountryMessage')}</div>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  onCloseButtonClick(): void;
}

const Box = ({ children, className, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className={classnames(className, 'box position-absolute w-100 bg-white shadow-sm py-4')} ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button className="p-2" iconEnd={{ name: 'close', size: 20 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
      </Container>
      {children}
    </div>
  );
};
