import './MultiColumnB.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { t } from '../i18n/i18n';

export interface MultiColumnBContentType {
  tiles?: b2x.contentTypes.ContentSectionList<{
    asset?: b2x.contentTypes.AssetContentSectionV2;
    content?: string;
    size?: string;
    title?: string;
  }>;
}

interface MultiColumnBProps extends MultiColumnBContentType {}

export const MultiColumnB = ({ tiles }: MultiColumnBProps) => {
  return (
    <b2x.Div className="multi-column-b" paddingY={{ lg: 5, xs: 3 }}>
      <Container>
        <b2x.Row gap={3}>
          {tiles?.map((tile) => (
            <b2x.Col key={tile.contentSectionId} size={{ lg: tile.size as b2x.ColSize, xs: 12 }}>
              <b2x.Div
                className="tile-container"
                display={'flex'}
                flexDirection={'column'}
                gap={3}
                marginBottom={{ lg: 5, xs: 4 }}
              >
                <b2x.P className="title" margin={0} paddingX={{ lg: 0, xs: 3 }}>
                  {tile.title}
                </b2x.P>
                <b2x.Div className="tile-inner-container">
                  <b2x.AssetV2 {...tile.asset} />
                  <b2x.Div
                    className="copy-container"
                    paddingBottom={5}
                    paddingTop={{ lg: 5, xs: 4 }}
                    paddingX={{ lg: 5, xs: 4 }}
                  >
                    <b2x.P className="content">{b2x.formatHtml(tile.content)}</b2x.P>
                  </b2x.Div>
                  <b2x.Div
                    alignItems={'end'}
                    className="read-more"
                    display={{ lg: 'none', xs: 'flex' }}
                    justifyContent={'center'}
                    paddingBottom={4}
                  >
                    <span>{t('misc.pageBuilder.multiColumnB.readMore')}</span>
                  </b2x.Div>
                </b2x.Div>
              </b2x.Div>
            </b2x.Col>
          ))}
        </b2x.Row>
      </Container>
    </b2x.Div>
  );
};
